import React from 'react';
import { loadModules } from 'esri-loader';
import { WebMapLegend } from './WebMapLegend';
import PulseLoader from "react-spinners/PulseLoader";
import ReactTooltip from "react-tooltip";
import { Color } from '../_helpers/Color';
import './WebMapView.css';

import { Location } from './Location';
import { Border } from '../../../../node_modules/react-bootstrap-icons/dist/index';

var map;
var view;
var bm;
export let drawPinOnMap;
export let filterMap;
export let zoomToMapObject;
export let filterLoc;
export let stopLoc;
export let setPoint;

var filterImmovable;
var clearfilterImmovable;
var filterMuseum;
var filterZone;

export class WebMapView extends React.Component {

    constructor(props) {
        super(props);
        const params = new URLSearchParams(window.location.search);
        var forType = params.get('t') || '';
        this.state = {
            loading: true,
            show: false,

            visZoneCheck: (this.props.type === 'all' && forType === 'immovable') || this.props.type === 'immovable' ? true : false,
            physZoneCheck: (this.props.type === 'all' && forType === 'immovable') || this.props.type === 'immovable' ? true : false,
        }
        this.mapRef = React.createRef();
        this.updateLoadingState = this.updateLoadingState.bind(this);

    }


    componentDidMount() {
        // lazy load the required ArcGIS API for JavaScript modules and CSS
        this.loadMap();
    }

    componentDidUpdate() {
        this.loadMap();

        // this.updateLoadingState(true);
    }

    updateLoadingState(value) {
        this.setState({ loading: value })
    }


    showMeasurement() {
        const el = document.getElementById("toolbarDiv");
        el.classList.remove('displayNone');
    }
    showSketchViewModelMeasurement() {
        const el = document.getElementById("toolbarSketchViewModelDiv");
        el.classList.remove('displayNone');
    }
    //showMyFilter() {
    //    const el = document.getElementById("toolbarDivTest");
    //    el.classList.remove('displayNone');;
    //}
    changeMapBaseMap() {
        if (bm.classList.contains('hybridImage')) {
            bm.classList.remove('hybridImage');
            bm.classList.add('osmImage');
            window.changeBaseMap('hybrid');

        } else {
            bm.classList.remove('osmImage');
            bm.classList.add('hybridImage');
            window.changeBaseMap('napr');
        }

    }
    async test(type, objList) {
        var immPath;
        var musPath;
        var zonePath;
        switch (type) {
            case "movable":
                immPath = `api/Immovable/GetLinkedIDsForMap?type=${type}`;
                const response = await fetch(immPath, {
                    method: "POST",
                    body: JSON.stringify(objList),
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                });
                const data = await response.json();
                filterImmovable(data);
                musPath = `api/Museum/GetLinkedIDsForMap?type=${type}`;
                const Musresponse = await fetch(musPath, {
                    method: "POST",
                    body: JSON.stringify(objList),
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                });
                const Musdata = await Musresponse.json();
                filterMuseum(Musdata);
                break;
            case "wallart":
                immPath = `api/Immovable/GetLinkedIDsForMap?type=${type}`;
                const w_response = await fetch(immPath, {
                    method: "POST",
                    body: JSON.stringify(objList),
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                });
                const w_data = await w_response.json();
                filterImmovable(w_data);
                musPath = `api/Museum/GetLinkedIDsForMap?type=${type}`;
                const w_Musresponse = await fetch(musPath, {
                    method: "POST",
                    body: JSON.stringify(objList),
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                });
                const w_Musdata = await w_Musresponse.json();
                filterMuseum(w_Musdata);
                break;
            case "stoneengrave":
                immPath = `api/Immovable/GetLinkedIDsForMap?type=${type}`;
                const s_response = await fetch(immPath, {
                    method: "POST",
                    body: JSON.stringify(objList),
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                });
                const s_data = await s_response.json();
                filterImmovable(s_data);
                musPath = `api/Museum/GetLinkedIDsForMap?type=${type}`;
                const s_Musresponse = await fetch(musPath, {
                    method: "POST",
                    body: JSON.stringify(objList),
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                });
                const s_Musdata = await s_Musresponse.json();
                filterMuseum(s_Musdata);
                break;
            case "event":
                immPath = `api/Immovable/GetLinkedIDsForMap?type=${type}`;
                const e_response = await fetch(immPath, {
                    method: "POST",
                    body: JSON.stringify(objList),
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                });
                const e_data = await e_response.json();
                filterImmovable(e_data);

                musPath = `api/Museum/GetLinkedIDsForMap?type=${type}`;
                const e_Musresponse = await fetch(musPath, {
                    method: "POST",
                    body: JSON.stringify(objList),
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                });
                const e_Musdata = await e_Musresponse.json();
                filterMuseum(e_Musdata);

                zonePath = `api/SafetyZone/GetLinkedIDsForMap?type=${type}`;
                const z_response = await fetch(zonePath, {
                    method: "POST",
                    body: JSON.stringify(objList),
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                });
                const z_data = await z_response.json();
                filterZone(z_data);
                break;
            case "all":
                const params = new URLSearchParams(window.location.search);
                var fileter = params.get('filterText') || '';

                var forType = params.get('t') || '';

                if (forType === "immovable") {
                    immPath = `api/Immovable/GetLinkedIDsForMap?type=${type}`;
                    const all_response = await fetch(immPath, {
                        method: "POST",
                        body: JSON.stringify(fileter),
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                    });
                    const all_data = await all_response.json();
                    filterMap(forType, all_data);
                }
                if (forType === "movable") {
                    immPath = `api/Movable/GetLinkedIDsForMap?type=${type}`;
                    const all_response = await fetch(immPath, {
                        method: "POST",
                        body: JSON.stringify(fileter),
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                    });
                    const all_data = await all_response.json();
                    filterMap(forType, all_data);
                }
                if (forType === "wallart") {
                    immPath = `api/WallArt/GetLinkedIDsForMap?type=${type}`;
                    const all_response = await fetch(immPath, {
                        method: "POST",
                        body: JSON.stringify(fileter),
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                    });
                    const all_data = await all_response.json();
                    filterMap(forType, all_data);
                }
                if (forType === "stoneengrave") {
                    immPath = `api/StoneEngrave/GetLinkedIDsForMap?type=${type}`;
                    const all_response = await fetch(immPath, {
                        method: "POST",
                        body: JSON.stringify(fileter),
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                    });
                    const all_data = await all_response.json();
                    filterMap(forType, all_data);
                }
                if (forType === "event") {
                    immPath = `api/Event/GetLinkedIDsForMap?type=${type}`;
                    const all_response = await fetch(immPath, {
                        method: "POST",
                        body: JSON.stringify(fileter),
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                    });
                    const all_data = await all_response.json();
                    filterMap(forType, all_data);
                }
                if (forType === "museum") {
                    musPath = `api/Museum/GetLinkedIDsForMap?type=${type}`;
                    const all_Musresponse = await fetch(musPath, {
                        method: "POST",
                        body: JSON.stringify(fileter),
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                    });
                    const all_Musdata = await all_Musresponse.json();
                    filterMap(forType, all_Musdata);
                }
                if (forType === "zone") {
                    zonePath = `api/SafetyZone/GetLinkedIDsForMap?type=${type}`;
                    const all_zoneresponse = await fetch(zonePath, {
                        method: "POST",
                        body: JSON.stringify(fileter),
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                    });
                    const all_zonedata = await all_zoneresponse.json();
                    filterMap(forType, all_zonedata);
                }
                break;
            case "text":
                const p = new URLSearchParams(window.location.search);
                fileter = p.get('filterText') || '';
                if (this.props.type === "immovable") {
                    immPath = `api/Immovable/GetLinkedIDsForMap?type=all`;
                    const all_response = await fetch(immPath, {
                        method: "POST",
                        body: JSON.stringify(fileter),
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                    });
                    const all_data = await all_response.json();
                    filterMap(this.props.type, all_data);
                }
                if (this.props.type === "movable") {
                    immPath = `api/Movable/GetLinkedIDsForMap?type=all`;
                    const all_response = await fetch(immPath, {
                        method: "POST",
                        body: JSON.stringify(fileter),
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                    });
                    const all_data = await all_response.json();
                    filterMap(this.props.type, all_data);
                }
                if (this.props.type === "wallart") {
                    immPath = `api/WallArt/GetLinkedIDsForMap?type=all`;
                    const all_response = await fetch(immPath, {
                        method: "POST",
                        body: JSON.stringify(fileter),
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                    });
                    const all_data = await all_response.json();
                    filterMap(this.props.type, all_data);
                }
                if (this.props.type === "stoneengrave") {
                    immPath = `api/StoneEngrave/GetLinkedIDsForMap?type=all`;
                    const all_response = await fetch(immPath, {
                        method: "POST",
                        body: JSON.stringify(fileter),
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                    });
                    const all_data = await all_response.json();
                    filterMap(this.props.type, all_data);
                }
                if (this.props.type === "event") {
                    immPath = `api/Event/GetLinkedIDsForMap?type=all`;
                    const all_response = await fetch(immPath, {
                        method: "POST",
                        body: JSON.stringify(fileter),
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                    });
                    const all_data = await all_response.json();
                    filterMap(this.props.type, all_data);
                }
                if (this.props.type === "museum") {
                    musPath = `api/Museum/GetLinkedIDsForMap?type=all`;
                    const all_Musresponse = await fetch(musPath, {
                        method: "POST",
                        body: JSON.stringify(fileter),
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                    });
                    const all_Musdata = await all_Musresponse.json();
                    filterMap(this.props.type, all_Musdata);
                }
                if (this.props.type === "zone") {
                    zonePath = `api/SafetyZone/GetLinkedIDsForMap?type=all`;
                    const all_zoneresponse = await fetch(zonePath, {
                        method: "POST",
                        body: JSON.stringify(fileter),
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                    });
                    const all_zonedata = await all_zoneresponse.json();
                    filterMap(this.props.type, all_zonedata);
                }
                break;
            default:

                break;
        }


    }

    async loadMap() {
        loadModules(["esri/intl", "esri/core/urlUtils", 'esri/Map',
            "esri/layers/TileLayer", "esri/Basemap", "esri/request",
            "esri/layers/BaseTileLayer", "esri/layers/WMSLayer",
            "esri/Color",
            "esri/layers/WebTileLayer",
            'esri/views/MapView', "esri/layers/MapImageLayer",
            "esri/layers/GraphicsLayer", "esri/Graphic",
            "esri/tasks/QueryTask",
            "esri/tasks/support/Query",
            "esri/geometry/support/webMercatorUtils",
            "esri/layers/FeatureLayer",
            "esri/widgets/Sketch/SketchViewModel",
            "esri/widgets/Expand",
            "esri/widgets/Legend",
            "esri/widgets/LayerList",
            "esri/geometry/Point",
            "esri/widgets/Measurement",
            "esri/widgets/Measurement/MeasurementViewModel",
            "esri/symbols/SimpleMarkerSymbol",
            "esri/widgets/Track",
            "esri/widgets/Locate",
            "esri/tasks/support/BufferParameters",
            "esri/geometry/SpatialReference",
            "esri/tasks/GeometryService",
            "esri/geometry/geometryEngine",
            "esri/geometry/geometryEngineAsync",
            "esri/widgets/Fullscreen",
            "esri/widgets/Fullscreen/FullscreenViewModel",
            "esri/core/watchUtils"
        ], { css: true })
            .then(([intl, urlUtils, ArcGISMap, TileLayer, Basemap, esriRequest, BaseTileLayer, WMSLayer, ColorBM,
                WebTileLayer,
                MapView, MapImageLayer,
                GraphicsLayer, Graphic, QueryTask, Query, webMercatorUtils, FeatureLayer, SketchViewModel, Expand, Legend, LayerList, Point, Measurement, MeasurementVM, SimpleMarkerSymbol,
                Track, Locate, BufferParameters, SpatialReference, GeometryService, geometryEngine, geometryEngineAsync, Fullscreen, FullscreenVM, watchUtils
            ]) => {

                var wgs = new SpatialReference({ "wkid": 4326 });

                let highlight;

                let propsType = this.props.type;
                let propsObjectID = this.props.objectID;
                let mapClass = this.props.mapClass;
                var tableView = this.props.tableView;


                var proxy = "https://memkvidreoba.gov.ge/DotNet/proxy.ashx?";

                var TintLayer = BaseTileLayer.createSubclass({
                    properties: {
                        urlTemplate: null,
                        tint: {
                            value: null,
                            type: ColorBM
                        }
                    },

                    // generate the tile url for a given level, row and column
                    getTileUrl: function (level, row, col) {
                        return this.urlTemplate
                            .replace("{z}", level)
                            .replace("{x}", col)
                            .replace("{y}", row);
                    },

                    // This method fetches tiles for the specified level and size.
                    // Override this method to process the data returned from the server.
                    fetchTile: function (level, row, col, options) {
                        // call getTileUrl() method to construct the URL to tiles
                        // for a given level, row and col provided by the LayerView
                        var url = this.getTileUrl(level, row, col);

                        // request for tiles based on the generated url
                        // the signal option ensures that obsolete requests are aborted
                        return esriRequest(url, {
                            responseType: "image",
                            signal: options && options.signal
                        }).then(
                            function (response) {
                                // when esri request resolves successfully
                                // get the image from the response
                                var image = response.data;
                                var width = this.tileInfo.size[0];
                                var height = this.tileInfo.size[0];

                                // create a canvas with 2D rendering context
                                var canvas = document.createElement("canvas");
                                var context = canvas.getContext("2d");
                                canvas.width = width;
                                canvas.height = height;

                                // Apply the tint color provided by
                                // by the application to the canvas
                                if (this.tint) {
                                    // Get a CSS color string in rgba form
                                    // representing the tint Color instance.
                                    context.fillStyle = this.tint.toCss();
                                    context.fillRect(0, 0, width, height);

                                    // Applies "difference" blending operation between canvas
                                    // and steman tiles. Difference blending operation subtracts
                                    // the bottom layer (canvas) from the top layer (tiles) or the
                                    // other way round to always get a positive value.
                                    context.globalCompositeOperation = "difference";
                                }

                                // Draw the blended image onto the canvas.
                                context.drawImage(image, 0, 0, width, height);

                                return canvas;
                            }.bind(this)
                        );
                    }
                });

                // *******************************************************
                // Start of JavaScript application
                // *******************************************************





                // Create a new instance of the TintLayer and set its properties
                var naprTileLayer = new TintLayer({
                    urlTemplate: proxy + "http://nt0.reestri.gov.ge/NGCache?x={x}&y={y}&z={z}&l=COVERAGE",
                    tint: new ColorBM("#000000"),
                    title: "NAPR"
                });





                // Create a Basemap with the WebTileLayer. The thumbnailUrl will be used for
                // the image in the BasemapToggle widget.


                var naprBaseMap = new Basemap({
                    baseLayers: [naprTileLayer]
                });


                //urlUtils.addProxyRule({
                //    urlPrefix: "http://mp.napr.gov.ge/ORTHO_2000_KFW/service",
                //    proxyUrl: "https://memkvidreoba.gov.ge/DotNet/proxy.ashx"
                //});

                //const layer = new WMSLayer({
                //    url: "http://mp.napr.gov.ge/ORTHO_2000_KFW/service",

                //});
                //სკეჩისთვის


                map = new ArcGISMap({
                    // basemap: naprBaseMap
                    basemap: 'hybrid'
                });
                // map.add(layer);

                view = new MapView({
                    container: this.mapRef.current,
                    map: map,
                    center: [43.20, 42.28],
                    zoom: 8
                });
                const graphicsLayerSketch = new GraphicsLayer({
                    title: "graphicsLayerSketch",
                    spatialReference: view.spatialReference
                });
                map.add(graphicsLayerSketch);
                //basemap
                urlUtils.addProxyRule({
                    urlPrefix: "http://mp.napr.gov.ge/ORTHO_GEORGIA/service",
                    proxyUrl: "https://memkvidreoba.gov.ge/DotNet/proxy.ashx"
                });
                urlUtils.addProxyRule({
                    urlPrefix: "http://mp.napr.gov.ge/ORTHO_2000_KFW/service",
                    proxyUrl: "https://memkvidreoba.gov.ge/DotNet/proxy.ashx"
                });
                urlUtils.addProxyRule({
                    urlPrefix: "http://mp.napr.gov.ge/TOPO_25k_1953-2004/service",
                    proxyUrl: "https://memkvidreoba.gov.ge/DotNet/proxy.ashx"
                });
                urlUtils.addProxyRule({
                    urlPrefix: "http://mp.napr.gov.ge/ORTHO_2016-17_NORV/service",
                    proxyUrl: "https://memkvidreoba.gov.ge/DotNet/proxy.ashx"
                });




                var wmsLayer_ortho_2000 = new WMSLayer({
                    url: "http://mp.napr.gov.ge/ORTHO_2000_KFW/service",
                    visible: false
                });

                var wmsLayer_topo_25000 = new WMSLayer({
                    url: "http://mp.napr.gov.ge/TOPO_25k_1953-2004/service",
                    visible: false
                });

                var wmsLayer_ortho_georgia = new WMSLayer({
                    url: "http://mp.napr.gov.ge/ORTHO_GEORGIA/service",
                    visible: false
                });

                var wmsLayer_ORTHO_2016 = new WMSLayer({
                    url: "http://mp.napr.gov.ge/ORTHO_2016-17_NORV/service",
                    visible: false
                });


                var track;
                var zoomSet = false;
                var shoMyLocationSet = false; // ეს არის ჩემი ლოკაციის ღილაკისთვის



                view.ui._removeComponents(["attribution"]);

                intl.setLocale("en-US");

                window.changeBaseMap = function (value) {


                    switch (value) {
                        case 'hybrid':
                            view.map.basemap = value;
                            wmsLayer_ortho_2000.visible = false;
                            wmsLayer_ORTHO_2016.visible = false;
                            wmsLayer_topo_25000.visible = false;
                            wmsLayer_ortho_georgia.visible = false;
                            break;
                        case 'napr':
                            view.map.basemap = naprBaseMap;
                            wmsLayer_ortho_2000.visible = false;
                            wmsLayer_ORTHO_2016.visible = false;
                            wmsLayer_topo_25000.visible = false;
                            wmsLayer_ortho_georgia.visible = false;
                            break;
                        case 'orto_Georgia':
                            wmsLayer_ortho_georgia.visible = !wmsLayer_ortho_georgia.visible;
                            wmsLayer_ortho_2000.visible = false;
                            wmsLayer_ORTHO_2016.visible = false;
                            wmsLayer_topo_25000.visible = false;

                            break;
                        case 'orto_2016':
                            wmsLayer_ORTHO_2016.visible = !wmsLayer_ORTHO_2016.visible;
                            wmsLayer_ortho_2000.visible = false;
                            wmsLayer_topo_25000.visible = false;
                            wmsLayer_ortho_georgia.visible = false;
                            break;
                        case 'orto_2000':
                            wmsLayer_ortho_2000.visible = !wmsLayer_ortho_2000.visible;
                            wmsLayer_ORTHO_2016.visible = false;
                            wmsLayer_topo_25000.visible = false;
                            wmsLayer_ortho_georgia.visible = false;
                            break;
                        case 'topo_25000':
                            wmsLayer_topo_25000.visible = !wmsLayer_topo_25000.visible;
                            wmsLayer_ortho_2000.visible = false;
                            wmsLayer_ORTHO_2016.visible = false;
                            wmsLayer_ortho_georgia.visible = false;
                            break;
                    }

                }

                window.checkZoneVisiblity = function () {

                    return visualZoneLayer.visible;

                }



                const expandBtn = document.createElement("div");
                expandBtn.id = "expandBtn";
                expandBtn.classList.add('esri-widget--button');
                expandBtn.classList.add('esricutomButton');
                expandBtn.classList.add('esri-icon-handle-horizontal');

                expandBtn.setAttribute('data-tooltip', 'მენიუ');
                expandBtn.addEventListener("click", function () {
                    showMenu();
                });
                const expandSketchViewModelBtn = document.createElement("div");
                expandSketchViewModelBtn.id = "expandSketchViewModelBtn";
                expandSketchViewModelBtn.classList.add('esri-widget--button');
                expandSketchViewModelBtn.classList.add('esricutomButton');
                expandSketchViewModelBtn.classList.add('esri-icon-handle-horizontal');

                expandSketchViewModelBtn.setAttribute('data-tooltip', 'სკეჩი');
                expandSketchViewModelBtn.addEventListener("click", function () {
                    showSketchViewModel();
                });


                const pinBtn = document.createElement("div");
                pinBtn.id = "locationMobileMapBtn";
                pinBtn.classList.add('esri-widget--button');
                pinBtn.classList.add('esricutomButton');
                pinBtn.classList.add('icon-pin-location');
                pinBtn.classList.add('esri-interactive');
                pinBtn.setAttribute('data-tooltip', 'ჩემთან ახლოს');


                if (this.props.type === "immovable" || this.props.type === "museum") {
                    if (this.props.objectID == null) {
                        view.ui.add(pinBtn, "bottom-right");
                    }
                }

                var showMyLocationDivEl = document.getElementById('showMyLocationDiv');
                var menuDivEl = document.getElementById('menuDiv');
                var sketchViewModelDivEl = document.getElementById('sketchViewModelDiv');
                var measureBtn = document.getElementById('measureButton');
                var toolbarDiv = document.getElementById('toolbarDiv');
                var toolbarSketchViewModelDiv = document.getElementById('toolbarSketchViewModelDiv');
                var searchEl = document.getElementById('MobileObjectSearch');



                if (searchEl != null) {
                    searchEl.classList.add('esri-widget--button');
                }
                //const collapseButton = document.querySelector('.esri-icon-collapse');
                //const propPanel = document.getElementById('propPanel');
                //collapseButton.addEventListener('click', function () {
                //    if (propPanel.style.display === 'none') {
                //        propPanel.style.display = 'flex';
                //    } else {
                //        propPanel.style.display = 'none';
                //    }
                //});

                //// Create a sketch view model
                //var sketchViewModel = new SketchViewModel({
                //    view: view,
                //    layer: immovableLayer,
                //    updateOnGraphicClick: false // Prevent updating graphics when clicked
                //});
                // Add the calcite-panel for the styler to an Expand to hide/show the panel
                //const stylerExpand = new Expand({
                //    view: view,
                //    content: document.getElementById("propPanel"),
                //    expanded: false,
                //    expandIcon: "pencil",
                //    expandTooltip: "Open Styler"
                //});



                // var filterTable = this.props.filterTableData;


                stopLoc = function () {
                    //locateWidget.cancelLocate();
                    if (track != null) {
                        track.stop();
                    }
                    sessionStorage.setItem('locationFilter', 'off');
                    sessionStorage.removeItem('locationfilterResult');
                    reload('');
                }




                var fullscreenVM = new FullscreenVM({
                    view: view
                });

                var objectfullscreen = new Fullscreen({
                    view: view,
                    viewModel: fullscreenVM
                });


                const fullScreen = document.createElement("div");
                fullScreen.classList.add('esri-widget--button');
                fullScreen.classList.add(this.props.fullScreeenIcon);
                fullScreen.onclick = this.props.fullClick;

                if (searchEl != null) {
                    view.ui.add(searchEl, "top-right");
                }
                view.ui.add(fullScreen, "top-right");



                bm = document.getElementById('bm');
                if (this.props.objectID == null) {

                    view.ui.add(menuDivEl, "bottom-right");
                    view.ui.add(sketchViewModelDivEl, "top-right");
                    // view.ui.add(expandBtn, "bottom-right");
                    view.ui.add(measureBtn, "top-left");
                    view.ui.add(showMyLocationDivEl, "top-left");
                    view.ui.add(toolbarDiv, "bottom-right");
                    view.ui.add(toolbarSketchViewModelDiv, "top-right");
                    //view.ui.add(toolbarDivTest, "top-right");
                    view.ui.add(bm, "bottom-left");
                    //view.ui.add(stylerExpand, "top-right"); // Add the calcite panel

                    measureBtn.classList.remove('displayNone');
                    menuDivEl.classList.remove('displayNone');
                    sketchViewModelDivEl.classList.remove('displayNone');
                    bm.classList.remove('displayNone');
                }
                else {
                    measureBtn.classList.add('displayNone');
                    menuDivEl.classList.add('displayNone');
                    sketchViewModelDivEl.classList.add('displayNone');
                }
                view.ui.move("zoom", "bottom-right");
                //if (this.props.objectID == null)
                //    view.ui.add(fullScreen, "top-right");
                //else {
                //    view.ui.add(objectfullscreen, "top-right");
                //}


                function showMenu() {
                    const el = document.getElementById("menuDiv");
                    if (el.classList.contains('displayNone'))
                        el.classList.remove('displayNone');
                    else el.classList.add('displayNone');
                }
                function showSketchViewModel() {
                    const el = document.getElementById("sketchViewModelDiv");
                    if (el.classList.contains('displayNone'))
                        el.classList.remove('displayNone');
                    else el.classList.add('displayNone');
                }

                function trackStart() {
                    zoomSet = false;
                    shoMyLocationSet = false; // ჩემი ლოკაციის ღილაკისთვის 
                    //track = new Track({
                    //    view: view,
                    //    goToLocationEnabled: false,
                    //    scale: 10000
                    //});

                    if (track != null) {

                        track.start();
                    }
                    // locateWidget.locate();

                }
                // ჩემი ლოკაციის ღილაკისთვის 
                function trackStartShowMyLocation() {
                    zoomSet = false;
                    shoMyLocationSet = true;
                    if (track != null) {

                        track.start();
                    }
                    // locateWidget.locate();

                }
                showMyLocationDivEl.addEventListener("click", function () {
                    trackStartShowMyLocation();
                });
                filterLoc = trackStart;
                ///////////measure

                var measurementVM = new MeasurementVM({
                    view: view
                });

                const measurement = new Measurement({
                    view: view,
                    areaUnit: "square-meters",
                    viewModel: measurementVM
                });
                view.ui.add(measurement, "top-left");

                // view.ui.add(measurement, "bottom-left");

                const distanceButton = document.getElementById("distance");
                const areaButton = document.getElementById("area");
                const clearButton = document.getElementById("clear");
                const closeButton = document.getElementById("close");
                const closeSketchViewModelButton = document.getElementById("closeSketchViewModel");
                const closeButtonTest = document.getElementById("closeTest");

                distanceButton.addEventListener("click", function () {
                    distanceMeasurement();
                });
                areaButton.addEventListener("click", function () {
                    areaMeasurement();
                });
                clearButton.addEventListener("click", function () {
                    clearMeasurements();
                });

                closeButton.addEventListener("click", function () {
                    closeMeasurements();
                    const el = document.getElementById("toolbarDiv");
                    el.classList.add('displayNone');
                });
                closeSketchViewModelButton.addEventListener("click", function () {
                    const el = document.getElementById("toolbarSketchViewModelDiv");
                    el.classList.add('displayNone');
                });
                //closeButtonTest.addEventListener("click", function () {
                //    closeMeasurements();
                //    const el = document.getElementById("toolbarDivTest");
                //    el.classList.add('displayNone');
                //});

                function distanceMeasurement() {
                    measurement.activeTool = "distance";
                    distanceButton.classList.add("active");
                    areaButton.classList.remove("active");
                }

                // Call the appropriate AreaMeasurement2D or AreaMeasurement3D
                function areaMeasurement() {
                    measurement.activeTool = "area";
                    distanceButton.classList.remove("active");
                    areaButton.classList.add("active");
                }

                // Clears all measurements
                function closeMeasurements() {

                    measurement.clear();
                }

                function clearMeasurements() {
                    distanceButton.classList.remove("active");
                    areaButton.classList.remove("active");
                    measurement.clear();
                }



                urlUtils.addProxyRule({
                    urlPrefix: "http://memkvidreoba.gov.ge:6080/arcgis/rest/services",
                    proxyUrl: "https://memkvidreoba.gov.ge/DotNet/proxy.ashx"

                });

                let reload = (result) => {
                    if (this.props.type === 'immovable' || this.props.type === 'museum') {
                        if (this.props.tableView != null && this.props.tableView.current != null) {
                            if (sessionStorage.getItem('locationFilter') === 'on') {
                                if (sessionStorage.getItem('filterResult') != null && sessionStorage.getItem('filterResult') !== '') {
                                    var array1 = sessionStorage.getItem('filterResult').split(',');
                                    var array2 = result.split(',');
                                    if (result !== '') {
                                        var r = array1.filter(value => array2.includes(value))
                                        if (r.length === 0) r = ['0'];
                                        this.props.tableView.current.filterResulthandle(r.join(','));
                                    }
                                    else {
                                        this.props.tableView.current.filterResulthandle(sessionStorage.getItem('filterResult'), true);
                                    }
                                }
                                else {
                                    this.props.tableView.current.filterResulthandle(result, true);
                                }
                            }
                            else {
                                if (sessionStorage.getItem('filterResult')) {
                                    this.props.tableView.current.filterResulthandle(sessionStorage.getItem('filterResult'));
                                }
                                else this.props.tableView.current.filterResulthandle(result);

                                //if (sessionStorage.getItem('PrevfilterResult')) {
                                //    sessionStorage.setItem('filterResult', sessionStorage.getItem('PrevfilterResult'));
                                //    sessionStorage.removeItem('PrevfilterResult');
                                //    this.props.tableView.current.filterResulthandle(sessionStorage.getItem('filterResult'));
                                //}
                                //else
                                //    this.props.tableView.current.filterResulthandle(result);
                            }

                        }
                        else {
                            filterImmovable(result);
                            //sessionStorage.setItem('filterResult', result);
                            sessionStorage.setItem('locationfilterResult', result);
                        }
                    }
                    else {
                        if (result === '') {
                            var filterResult = sessionStorage.getItem('filterResult') || '';
                            filterMap(this.props.type, filterResult);
                        }
                        else {
                            var fileter;
                            if (this.props.type === 'all') {
                                const params = new URLSearchParams(window.location.search);
                                fileter = params.get('filterText') || '';
                            }
                            else {
                                fileter = sessionStorage.getItem('filterResult') || '';
                            }

                            var immPath = `api/Immovable/GetLinkedIDsForMap?type=` + this.props.type;
                            fetch(immPath, {
                                method: "POST",
                                body: JSON.stringify(fileter),
                                headers: {
                                    'Accept': 'application/json',
                                    'Content-Type': 'application/json'
                                },
                            }).then(response => response.json())
                                .then(data => {
                                    var array1 = data !== '' ? data.split(',') : [];
                                    var array2 = result !== '' ? result.split(',') : [];
                                    var r = array1.filter(value => array2.includes(value))
                                    if (r.length === 0) r = ['0'];
                                    filterImmovable(r.join(','));
                                    //this.props.tableView.current.filterResulthandle(r.join(','));
                                });
                        }
                    }


                }

                function locationFilter(response) {
                    var result;
                    if (response.features.length === 0) {
                        result = "0";
                    }
                    else {
                        var resultList = response.features.map(function (feature) {
                            return feature.attributes.ImmovableObjectID;
                        }, this);
                        result = resultList.join(',');
                    }

                    //filterImmovable(result);
                    //sessionStorage.setItem('filterResult', result);
                    //filterTable(result);

                    reload(result);

                }


                function museumlocationFilter(response) {
                    var result;
                    if (response.features.length === 0) {
                        result = "0";
                    }
                    else {
                        var resultList = response.features.map(function (feature) {
                            return feature.attributes.MuseumID;
                        }, this);
                        result = resultList.join(',');
                    }

                    reload(result);

                }

                function addToMap(response) {
                    if (response != null && response.features != null) {
                        var peakResults = response.features.map(function (feature) {
                            var pointGraphic = new Graphic({
                                geometry: {
                                    type: "point",
                                    longitude: feature.attributes.X,
                                    latitude: feature.attributes.Y,
                                },
                                symbol: {

                                    type: "picture-marker",
                                    url: "/mapicons/pin.png",
                                    yoffset: "14px",
                                    width: "28px",
                                    height: "28px"
                                }
                            });
                            return pointGraphic;
                        }, this);
                        resultsLayer.addMany(peakResults);
                    }
                }

                function addZoneToMap(response) {
                    if (response != null && response.features != null) {
                        var peakResults = response.features.map(function (feature) {

                            var r = webMercatorUtils.xyToLngLat(feature.geometry.centroid.x, feature.geometry.centroid.y)

                            var pointGraphic = new Graphic({
                                geometry: {
                                    type: "point",
                                    longitude: r[0],
                                    latitude: r[1],
                                },
                                symbol: {

                                    type: "picture-marker",
                                    url: "/mapicons/pin.png",
                                    yoffset: "14px",
                                    width: "28px",
                                    height: "28px"
                                }
                            });
                            return pointGraphic;
                        }, this);
                        resultsLayer.addMany(peakResults);
                    }
                }

                function addMuseumPolyToMap(response) {
                    if (response != null && response.features != null) {
                        var peakResults = response.features.map(function (feature) {

                            var r = webMercatorUtils.xyToLngLat(feature.geometry.centroid.x, feature.geometry.centroid.y)

                            var pointGraphic = new Graphic({
                                geometry: {
                                    type: "point",
                                    longitude: r[0],
                                    latitude: r[1],
                                },
                                symbol: {

                                    type: "picture-marker",
                                    url: "/mapicons/pin.png",
                                    yoffset: "14px",
                                    width: "28px",
                                    height: "28px"
                                }
                            });
                            return pointGraphic;
                        }, this);
                        resultsLayer.addMany(peakResults);
                    }
                }

                drawPinOnMap = function (type, objID) {
                    if (resultsLayer != null) {
                        resultsLayer.removeAll();
                    }
                    switch (type) {
                        case 'immovable':
                            if (objID != null) {

                                var qTask = new QueryTask({
                                    url: "http://memkvidreoba.gov.ge:6080/arcgis/rest/services/Culture/TestPoints/MapServer/0"
                                });
                                var params = new Query({
                                    returnGeometry: false,

                                    outFields: ["X", "Y"]

                                });
                                params.where = "ImmovableObjectID in ( " + objID + ")";
                                qTask.execute(params).then(addToMap.bind(this)).catch();
                            }
                            break;
                        case 'museum':
                            if (objID != null) {

                                qTask = new QueryTask({
                                    url: "http://memkvidreoba.gov.ge:6080/arcgis/rest/services/Culture/CulturePortalService/MapServer/7"
                                });
                                params = new Query({
                                    returnGeometry: true,
                                    outFields: ["X", "Y"]

                                });
                                params.where = "MuseumID in ( " + objID + ")";
                                qTask.execute(params).then(addToMap.bind(this)).catch();

                                qTask = new QueryTask({
                                    url: "http://memkvidreoba.gov.ge:6080/arcgis/rest/services/Culture/CulturePortalService/MapServer/8"
                                });
                                params = new Query({
                                    returnGeometry: true,
                                    outFields: ["OBJECTID"]

                                });
                                params.where = "MuseumID in ( " + objID + ")";
                                params.outSpatialReference = { wkid: 102100 };
                                qTask.execute(params).then(addMuseumPolyToMap(this)).catch();

                            }
                            break;

                        case 'zone':
                            if (objID != null) {

                                qTask = new QueryTask({
                                    url: "http://memkvidreoba.gov.ge:6080/arcgis/rest/services/Culture/CultureService/MapServer/4",
                                });
                                params = new Query({
                                    returnGeometry: true,
                                    outFields: ["OBJECTID"]

                                });
                                params.where = "ZoneID in ( " + objID + ")";
                                params.outSpatialReference = { wkid: 102100 };
                                qTask.execute(params).then(addZoneToMap.bind(this)).catch();


                            }
                            break;
                        default:
                            break;
                    }
                }

                filterMap = function (type, objList) {
                    switch (type) {
                        case 'immovable':
                            filterImmovable(objList);
                            worldZoneBuffer.visible = true;
                            worldZonePoint.visible = true;
                            visualZoneLayer.visible = true;
                            physZoneLayer.visible = true;
                            immovableLayer.visible = true;
                            Flayer.visible = true;
                            break;
                        case 'movable':
                            this.test(type, objList);
                            visualZoneLayer.visible = true;
                            physZoneLayer.visible = true;
                            immovableLayer.visible = true;
                            Flayer.visible = true;
                            museumLayer.visible = true;
                            museumPointLayer.visible = true;
                            break;
                        case 'wallart':
                            this.test(type, objList);
                            visualZoneLayer.visible = true;
                            physZoneLayer.visible = true;
                            immovableLayer.visible = true;
                            Flayer.visible = true;
                            museumLayer.visible = true;
                            museumPointLayer.visible = true;

                            break;
                        case 'stoneengrave':
                            this.test(type, objList);
                            visualZoneLayer.visible = true;
                            physZoneLayer.visible = true;
                            immovableLayer.visible = true;
                            Flayer.visible = true;
                            museumLayer.visible = true;
                            museumPointLayer.visible = true;

                            break;
                        case 'museum':
                            filterMuseum(objList);
                            museumLayer.visible = true;
                            museumPointLayer.visible = true;
                            break;
                        case 'zone':
                            filterZone(objList);
                            archzone.visible = true;
                            istzone.visible = true;
                            regzone.visible = true;
                            landzone.visible = true;
                            break;
                        case 'event':
                            this.test(type, objList);
                            visualZoneLayer.visible = true;
                            physZoneLayer.visible = true;
                            immovableLayer.visible = true;
                            Flayer.visible = true;
                            museumLayer.visible = true;
                            museumPointLayer.visible = true;
                            archzone.visible = true;
                            istzone.visible = true;
                            regzone.visible = true;
                            landzone.visible = true;
                            break;
                        case 'all':
                            this.test(type, objList);
                            break;
                        case 'text':
                            this.test(type, objList);
                            break;
                        default:
                            break;
                    }

                    if (sessionStorage.getItem('locationFilter') === 'on') {
                        visualZoneLayer.visible = false;
                        physZoneLayer.visible = false;
                        //  this.setState({ physZoneCheck: false, visZoneCheck: false });
                    }
                }

                filterMap = filterMap.bind(this);

                zoomToMapObject = function (type, objID) {
                    if (selectionLayer != null)
                        selectionLayer.removeAll();

                    switch (type) {
                        case 'immovable':
                            if (objID != null) {

                                var qTask = new QueryTask({
                                    url: "http://memkvidreoba.gov.ge:6080/arcgis/rest/services/Culture/TestPoints/MapServer/0"
                                });
                                var params = new Query({
                                    returnGeometry: false,
                                    outFields: ["X", "Y"]

                                });
                                params.where = "ImmovableObjectID in ( " + objID + ")";
                                qTask.execute(params).then(getResults.bind(this)).catch();


                            }
                            break;

                        case 'museum':
                            if (objID != null) {

                                qTask = new QueryTask({
                                    url: "http://memkvidreoba.gov.ge:6080/arcgis/rest/services/Culture/CulturePortalService/MapServer/7"
                                });
                                params = new Query({
                                    returnGeometry: true,
                                    outFields: ["X", "Y"]

                                });
                                params.where = "MuseumID in ( " + objID + ")";
                                qTask.execute(params).then(getMuseumResults.bind(this)).catch();

                                qTask = new QueryTask({
                                    url: "http://memkvidreoba.gov.ge:6080/arcgis/rest/services/Culture/CulturePortalService/MapServer/8"
                                });
                                params = new Query({
                                    returnGeometry: true,
                                    outFields: ["OBJECTID"]

                                });
                                params.where = "MuseumID in ( " + objID + ")";
                                params.outSpatialReference = { wkid: 102100 };
                                qTask.execute(params).then(getMuseumPolyResults.bind(this)).catch();
                            }
                            break;

                        case 'zone':
                            if (objID != null) {

                                qTask = new QueryTask({
                                    url: "http://memkvidreoba.gov.ge:6080/arcgis/rest/services/Culture/CultureService/MapServer/4",
                                });
                                params = new Query({
                                    returnGeometry: true,
                                    outFields: ["OBJECTID"]

                                });
                                params.where = "ZoneID in ( " + objID + ")";
                                params.outSpatialReference = { wkid: 102100 };
                                qTask.execute(params).then(getZoneResults.bind(this)).catch();


                            }
                            break;
                        case 'userObject':
                            if (objID != null) {
                                view.center = [objID.split(';')[0], objID.split(';')[1]];  // Sets the center point of the view at a specified lon/lat
                                view.zoom = 16;
                            }
                            break;
                        default:
                            break;
                    }
                }

                zoomToMapObject = zoomToMapObject.bind(this);

                window.changeVisibility = function (layer, value) {

                    switch (layer) {
                        case "arch":
                            archzone.visible = value;
                            createZonePoint((zoneObjectIDS.length > 0 ? zoneObjectIDS : ''), true);
                            break;
                        case "land":
                            landzone.visible = value;
                            createZonePoint((zoneObjectIDS.length > 0 ? zoneObjectIDS : ''), true);
                            break;
                        case "reg":
                            regzone.visible = value;
                            createZonePoint((zoneObjectIDS.length > 0 ? zoneObjectIDS : ''), true);
                            break;
                        case "istgan":
                            istzone.visible = value;
                            createZonePoint((zoneObjectIDS.length > 0 ? zoneObjectIDS : ''), true);
                            break;
                        case "world":
                            worldZoneBuffer.visible = value;
                        case "vis":
                            visualZoneLayer.visible = value;
                            break;
                        case "phys":
                            physZoneLayer.visible = value;
                            break;
                        case "imm":
                            immovableLayer.visible = value;
                            break;
                        case "immPoint":
                            Flayer.visible = value;
                            break;
                        case "musRes":
                            museumLayer.visible = value;
                            break;
                        case "museum":
                            museumPointLayer.visible = value;
                            break;
                        default:
                            break;
                    }
                }
                window.changeOpacity = function (layer, value) {
                    switch (layer) {
                        case "arch":
                            archzone.opacity = value;
                            break;
                        case "land":
                            landzone.opacity = value;
                            break;
                        case "reg":
                            regzone.opacity = value;
                            break;
                        case "istgan":
                            istzone.opacity = value;
                            break;
                        case "world":
                            worldZoneBuffer.opacity = value;
                            break;
                        case "vis":
                            visualZoneLayer.opacity = value;
                            break;
                        case "phys":
                            physZoneLayer.opacity = value;
                            break;
                        case "imm":
                            immovableLayer.opacity = value;
                            break;
                        case "immPoint":
                            Flayer.opacity = value;
                            break;
                        case "musRes":
                            museumLayer.opacity = value;
                            break;
                        case "museum":
                            museumPointLayer.opacity = value;
                            break;
                        default:
                            break;
                    }
                }



                map.add(wmsLayer_ortho_2000);
                map.add(wmsLayer_topo_25000);
                map.add(wmsLayer_ortho_georgia);
                map.add(wmsLayer_ORTHO_2016);


                var qTask;
                var params;
                var filterResult;
                var expression;
                // ----------------------zone --------------------------

                var zoneObjectIDS = [];
                var updateObjectIDS = true;
                function drawZonePointsResults(response) {
                    if (response != null && response.features != null) {
                        var peakResults = response.features.map(function (feature) {
                            var r = webMercatorUtils.xyToLngLat(feature.geometry.centroid.x, feature.geometry.centroid.y)
                            var pointGraphic = new Graphic({
                                geometry: {
                                    type: "point",
                                    longitude: r[0],
                                    latitude: r[1],
                                },
                                symbol: {

                                    type: "picture-marker",
                                    url: "/mapicons/zone.svg",
                                    yoffset: "14px",
                                    width: "28px",
                                    height: "28px"
                                },
                                attributes: feature.attributes
                            });
                            return pointGraphic;
                        }, this);
                        zonePointsLayer.removeAll();
                        zonePointsLayer.addMany(peakResults);
                        if (updateObjectIDS === true) {
                            zoneObjectIDS = zonePointsLayer.graphics.map(function (gr) {
                                return gr.attributes.OBJECTID;
                            }, this);
                        }
                    }
                }



                function createZonePoint(filterResult, byID) {
                    var fValue = (byID === true ? 'OBJECTID' : 'ZoneID');
                    var expression = filterResult === '' ? '' : fValue + " in (" + filterResult + ")";

                    var activeZones = [];
                    if (archzone.visible === true)
                        activeZones.push(14);
                    if (regzone.visible === true)
                        activeZones.push(12);
                    if (landzone.visible === true)
                        activeZones.push(13);
                    if (istzone.visible === true)
                        activeZones.push(11);

                    if (activeZones.length === 0) activeZones.push(0);
                    var zonePointExpressiont = expression === '' ? (activeZones.length > 0 ? 'ZoneType  in (' + activeZones.join(',') + ')' : '') : expression + (activeZones.length > 0 ? ' and ZoneType  in (' + activeZones.join(',') + ')' : '');

                    qTask = new QueryTask({
                        url: "http://memkvidreoba.gov.ge:6080/arcgis/rest/services/Culture/CultureService/MapServer/4",
                    });
                    params = new Query({
                        returnGeometry: true,
                        outFields: ["PhotoAddress", "ZoneID", "Name", "ZoneType", "OBJECTID"]

                    });
                    params.where = zonePointExpressiont;
                    params.outSpatialReference = { wkid: 102100 };
                    qTask.execute(params).then(drawZonePointsResults.bind(this)).catch();

                    if (byID === true) updateObjectIDS = false;
                    else updateObjectIDS = true;

                }

                filterZone = function filterZone(fr) {
                    var r = fr || '';
                    var loc = sessionStorage.getItem('locationfilterResult') || '';

                    var array1 = r !== '' ? r.split(',') : [];
                    var array2 = loc !== '' ? loc.split(',') : [];


                    var filterResult = array1.length > 0 ? array1.filter(value => array2.includes(value) || array2.length === 0) : array2

                    if (array2.length > 0 && filterResult.length === 0) filterResult = ['0'];

                    filterResult = filterResult.join(',');

                    var expression = filterResult === '' ? '' : "ZoneID in (" + filterResult + ")";

                    archzone.findSublayerById(0).definitionExpression = expression;
                    regzone.findSublayerById(2).definitionExpression = expression;
                    landzone.findSublayerById(1).definitionExpression = expression;
                    istzone.findSublayerById(3).definitionExpression = expression;

                    createZonePoint(filterResult, false);

                }


                if (this.props.type === "zone") {
                    filterResult = sessionStorage.getItem('filterResult') || '';
                    expression = filterResult === '' ? '' : "ZoneID in (" + filterResult + ")";
                }
                var archzone = new MapImageLayer({
                    url: "http://memkvidreoba.gov.ge:6080/arcgis/rest/services/Culture/CulturePortalService/MapServer",
                    listMode: "hide-children",
                    title: "არქეოლოგიური დაცვის ზონა",
                    visible: this.props.type === "zone" ? true : false,
                    opacity: .8,
                    sublayers: [
                        {
                            id: 0,

                            popupTemplate: this.props.objectID == null ? {
                                title: "",
                                outFields: ["PhotoAddress", "ZoneID", "Name"],
                                content: zonePopup
                            } : null,
                            definitionExpression: (this.props.type === "zone") ? (this.props.objectID != null ? "ZoneID in (" + this.props.objectID + ")" : expression) : ""
                        },
                    ]
                });
                map.add(archzone);

                var landzone = new MapImageLayer({
                    url: "http://memkvidreoba.gov.ge:6080/arcgis/rest/services/Culture/CulturePortalService/MapServer",
                    title: "ისტორიული ლანდშაფტის დაცვის ზონა",
                    listMode: "hide-children",
                    visible: this.props.type === "zone" ? true : false,
                    opacity: .8,
                    sublayers: [
                        {
                            id: 1,

                            popupTemplate: this.props.objectID == null ? {
                                title: "",
                                outFields: ["PhotoAddress", "ZoneID", "Name"],
                                content: zonePopup
                            } : null,
                            definitionExpression: (this.props.type === "zone") ? (this.props.objectID != null ? "ZoneID in (" + this.props.objectID + ")" : expression) : ""
                        },
                    ]
                });
                map.add(landzone);

                var regzone = new MapImageLayer({
                    url: "http://memkvidreoba.gov.ge:6080/arcgis/rest/services/Culture/CulturePortalService/MapServer",
                    listMode: "hide-children",
                    title: "განაშენიანების რეგულირების ზონა",
                    visible: this.props.type === "zone" ? true : false,
                    opacity: .8,
                    sublayers: [
                        {
                            id: 2,

                            popupTemplate: this.props.objectID == null ? {
                                title: "",
                                outFields: ["PhotoAddress", "ZoneID", "Name"],
                                content: zonePopup
                            } : null,
                            definitionExpression: (this.props.type === "zone") ? (this.props.objectID != null ? "ZoneID in (" + this.props.objectID + ")" : expression) : ""
                        },
                    ]
                });
                map.add(regzone);

                var istzone = new MapImageLayer({
                    url: "http://memkvidreoba.gov.ge:6080/arcgis/rest/services/Culture/CulturePortalService/MapServer",
                    title: "ისტორიული განაშენიანების დამცავი ზონა",
                    listMode: "hide-children",
                    visible: this.props.type === "zone" ? true : false,
                    opacity: .8,
                    sublayers: [
                        {
                            id: 3,


                            popupTemplate: this.props.objectID == null ? {
                                title: "",
                                outFields: ["PhotoAddress", "ZoneID", "Name"],
                                content: zonePopup
                            } : null,
                            definitionExpression: (this.props.type === "zone") ? (this.props.objectID != null ? "ZoneID in (" + this.props.objectID + ")" : expression) : ""
                        },
                    ]
                });
                map.add(istzone);



                createZonePoint(this.props.objectID != null ? this.props.objectID : '', false);

                if (this.props.type === "zone" && this.props.objectID != null) {
                    qTask = new QueryTask({
                        url: "http://memkvidreoba.gov.ge:6080/arcgis/rest/services/Culture/CultureService/MapServer/4",
                    });
                    params = new Query({
                        returnGeometry: true,
                        outFields: ["OBJECTID "]
                    });
                    params.where = "ZoneID in (" + this.props.objectID + ")";
                    params.outSpatialReference = { wkid: 102100 };
                    qTask.execute(params).then(getZoneResults.bind(this)).catch();
                }

                function getZoneResults(response) {

                    if (response != null && response.features != null) {
                        var peakResults = response.features.map(function (feature) {
                            var pointGraphic = new Graphic({
                                geometry: response.features[0].geometry,
                                symbol: {
                                    type: "simple-fill",  // autocasts as new SimpleFillSymbol()
                                    color: "transparent",
                                    style: "solid",
                                    outline: {  // autocasts as new SimpleLineSymbol()
                                        color: "white",
                                        width: 3
                                    }
                                }
                            });
                            return pointGraphic;
                        }, this);
                        selectionLayer.addMany(peakResults);
                    }

                    if (response.features.length > 0) {
                        var r = webMercatorUtils.xyToLngLat(response.features[0].geometry.centroid.x, response.features[0].geometry.centroid.y)
                        view.center = [r[0], r[1]];
                        view.zoom = 12;
                    }
                }

                // ----------------------immovable --------------------------

                var worldZoneBuffer;
                var worldZonePoint;

                var Flayer;
                var visualZoneLayer;
                var physZoneLayer;
                var immovableLayer;

                function immovablePopup() {
                    var result =
                        "<div class='popupContent'>" +
                        "<div class='popupContentImageDiv'>" +
                        "<img src='" + proxy + "{PhotoAddress}' alt='' class='popupContentImage'/></div>" +
                        "<a href='" + window.location.origin + "/objects/immovable/immovableObject?id={ImmovableObjectID}'>" +
                        "<div style='background-color:#F6BD60;font-family:FiraGO_book;font-size:15px;line-height: 21px;padding:10px;cursor:pointer'>" +
                        "<div style='color:#000000;'>{ObjectName} - #{ImmovableObjectID}</div>" +
                        "<div style='color:#636363;font-size:12px;'>{VarietyName}</div>" +
                        "</div></a></div>";

                    return result;
                }
                function museumPopup(feature) {
                    var result =
                        "<div class='popupContent'>" +
                        "<div class='popupContentImageDiv'><img src='" + proxy + "{PhotoAddress}' alt='' class='popupContentImage'/></div>" +
                        "<a href='" + window.location.origin + "/objects/museum/museumObject?id={MuseumID}'><div style='background-color:#F6BD60;font-family:FiraGO_book;font-size:15px;line-height: 21px;padding:10px;cursor:pointer'>" +
                        "<div style='color: #000000;'>{Name} - #{MuseumID}</div>" +
                        "</div></a>" +
                        "</div>";
                    return result;
                }
                function zonePopup(feature) {
                    var result =
                        "<div class='popupContent'>" +
                        "<div class='popupContentImageDiv'><img src='" + proxy + "{PhotoAddress}' alt='' class='popupContentImage'/></div>" +
                        "<a href='" + window.location.origin + "/objects/zone/zoneObject?id={ZoneID}'><div style='background-color:#F6BD60;font-family:FiraGO_book;font-size:15px;line-height: 21px;padding:10px;cursor:pointer'>" +
                        "<div style='color: #000000;'>{Name} - #{ZoneID}</div>" +
                        "</div></a>" +
                        "</div>";
                    return result;
                }

                filterImmovable = function filterImmovable(fr) {

                    var r = fr || '';
                    var loc = sessionStorage.getItem('locationfilterResult') || '';

                    var array1 = r !== '' ? r.split(',') : [];
                    var array2 = loc !== '' ? loc.split(',') : [];


                    var filterResult = array1.length > 0 ? array1.filter(value => array2.includes(value) || array2.length === 0) : array2

                    if (array2.length > 0 && filterResult.length === 0) filterResult = ['0'];

                    filterResult = filterResult.join(',');


                    var expression = filterResult === '' ? '' : "ImmovableObjectID in (" + filterResult + ")";
                    var zoneexpression = filterResult === '' ? '' : "ImmovableDataID in (" + filterResult + ")";

                    visualZoneLayer.findSublayerById(6).definitionExpression = zoneexpression;
                    physZoneLayer.findSublayerById(5).definitionExpression = zoneexpression;
                    immovableLayer.findSublayerById(4).definitionExpression = expression;

                    worldZoneBuffer.findSublayerById(1).definitionExpression = expression;
                    worldZonePoint.findSublayerById(0).definitionExpression = expression;

                    Flayer.definitionExpression = expression;


                }
                clearfilterImmovable = function clearfilterImmovable() {


                    visualZoneLayer.findSublayerById(6).definitionExpression = "";
                    physZoneLayer.findSublayerById(5).definitionExpression = "";
                    immovableLayer.findSublayerById(4).definitionExpression = "";

                    worldZoneBuffer.findSublayerById(1).definitionExpression = "";
                    worldZonePoint.findSublayerById(0).definitionExpression = "";

                    Flayer.definitionExpression = "";

                }


                function getResults(response) {
                    if (response != null && response.features != null) {
                        var peakResults = response.features.map(function (feature) {
                            var pointGraphic = new Graphic({
                                geometry: {
                                    type: "point",
                                    longitude: feature.attributes.X,
                                    latitude: feature.attributes.Y,
                                },
                                symbol: {
                                    type: "simple-marker",
                                    style: "circle",
                                    color: "transparent",
                                    size: "26px",  // pixels
                                    outline: {
                                        color: [255, 255, 255],
                                        width: 3  // points
                                    }
                                }
                            });
                            return pointGraphic;
                        }, this);
                        selectionLayer.addMany(peakResults);
                    }


                    if (response.features.length > 0) {
                        view.center = [response.features[0].attributes.X, response.features[0].attributes.Y];  // Sets the center point of the view at a specified lon/lat
                        view.zoom = 16;
                    }
                }


                if (this.props.type === 'immovable') {
                    //filterResult = sessionStorage.getItem('filterResult') || '';

                    var r = sessionStorage.getItem('filterResult') || '';
                    var loc = sessionStorage.getItem('locationfilterResult') || '';

                    var array1 = r !== '' ? r.split(',') : [];
                    var array2 = loc !== '' ? loc.split(',') : [];


                    filterResult = array1.length > 0 ? array1.filter(value => array2.includes(value) || array2.length === 0) : array2

                    if (array2.length > 0 && filterResult.length === 0) filterResult = ['0'];

                    filterResult = filterResult.join(',');

                    expression = filterResult === '' ? '' : "ImmovableObjectID in (" + filterResult + ")";
                }


                var zoneexpression = filterResult === '' ? '' : "ImmovableDataID in (" + filterResult + ")";

                worldZoneBuffer = new MapImageLayer({
                    url: "http://memkvidreoba.gov.ge:6080/arcgis/rest/services/Culture/WorldHeritage/MapServer",
                    listMode: "hide-children",
                    title: "მსოფლიო მემკვიდრეობის ძეგლის ბუფერული ზონა",
                    visible: this.props.type === "immovable" ? true : false,
                    opacity: .7,
                    sublayers: [
                        {
                            id: 1,
                            title: " ",

                            definitionExpression: this.props.type === "immovable" ? (this.props.objectID != null ? "ImmovableObjectID in (" + this.props.objectID + ")" : expression) : "",
                        },

                    ],
                    //minScale: 10000,
                });
                map.add(worldZoneBuffer);



                visualZoneLayer = new MapImageLayer({
                    url: "http://memkvidreoba.gov.ge:6080/arcgis/rest/services/Culture/CulturePortalService/MapServer",
                    title: "ძეგლის ვიზუალური დაცვის არეალი",
                    visible: this.props.type === "immovable" && sessionStorage.getItem('locationFilter') !== 'on' ? true : false,
                    opacity: .5,
                    listMode: "hide-children",
                    sublayers: [
                        {
                            title: " ",
                            id: 6,

                            definitionExpression: this.props.type === "immovable" ? (this.props.objectID != null ? "ImmovableDataID in (" + this.props.objectID + ")" : zoneexpression) : ""
                        }
                    ],
                    minScale: 150000,
                    maxScale: 4000,
                });
                map.add(visualZoneLayer);

                physZoneLayer = new MapImageLayer({
                    url: "http://memkvidreoba.gov.ge:6080/arcgis/rest/services/Culture/CulturePortalService/MapServer",
                    title: "ძეგლის ფიზიკური დაცვის არეალი",
                    listMode: "hide-children",
                    visible: this.props.type === "immovable" && sessionStorage.getItem('locationFilter') !== 'on' ? true : false,
                    opacity: .5,
                    sublayers: [
                        {
                            title: " ",
                            id: 5,

                            definitionExpression: this.props.type === "immovable" ? (this.props.objectID != null ? "ImmovableDataID in (" + this.props.objectID + ")" : zoneexpression) : "",

                        }
                    ],
                    minScale: 20000,
                });
                map.add(physZoneLayer);

                immovableLayer = new MapImageLayer({
                    url: "http://memkvidreoba.gov.ge:6080/arcgis/rest/services/Culture/CulturePortalService/MapServer",
                    listMode: "hide-children",
                    title: "ძეგლის/ობიექტის საზღვარი",
                    visible: this.props.type === "immovable" ? true : false,

                    opacity: .7,
                    sublayers: [
                        {
                            id: 4,
                            title: " ",

                            definitionExpression: this.props.type === "immovable" ? (this.props.objectID != null ? "ImmovableObjectID in (" + this.props.objectID + ")" : expression) : "",
                        },

                    ],
                    minScale: 10000,
                });
                map.add(immovableLayer);



                /* აქ არის სკეჩით მონიშვნა რუკაზე */

                const polygonSymbol = {
                    type: "simple-fill", // autocasts as new SimpleFillSymbol()
                    color: "#F2BC94",
                    outline: {
                        // autocasts as new SimpleLineSymbol()
                        color: "#722620",
                        width: 3
                    }
                };

                const sketchVM = new SketchViewModel({
                    view: view,
                    layer: graphicsLayerSketch,
                    polygonSymbol: polygonSymbol
                });
                const polygonBtn = document.getElementById("polygonBtn");
                const filterBtn = document.getElementById("filterBtn");
                //const clearSketchBtn = document.getElementById("clearSketchBtn");
                const clearBtn = document.getElementById("clearBtn");

                var queryGeometrySelected = null;
                polygonBtn.onclick = () => { sketchVM.layer.removeAll(); sketchVM.create("polygon");  }
                filterBtn.onclick = () => { if (queryGeometrySelected != null) selectFeatures(queryGeometrySelected) }

                clearBtn.onclick = () => { sketchVM.layer.removeAll(); sessionStorage.setItem('filterResult', ''); reload(''); clearfilterImmovable(); queryGeometrySelected = null; }
               // clearSketchBtn.onclick = () => { sketchVM.layer.removeAll(); }


                // Listen for the sketch's create event
                var isSketcOnMap = false;



                sketchVM.on("create", async (event) => {
                    if (event.state === "complete") {
                        
                        if (isSketcOnMap) {
                            sketchVM.layer.removeAll();
                            isSketcOnMap = false;
                        }

                        // this polygon will be used to query features that intersect it
                        const geometries = graphicsLayerSketch.graphics.map(function (graphic) {
                            return graphic.geometry;
                        });
                        const queryGeometry = await geometryEngineAsync.union(geometries.toArray());
                        queryGeometrySelected = queryGeometry;
                        
                    }
                });
                function selectFeatures(geometry) {
                    const query = {
                        geometry: geometry,
                        outFields: ["*"]
                    };

                    Flayer
                        .queryFeatures(query)
                        .then((results) => {
                            if (results.features.length === 0) {
                                //clearSelection();
                            } else {
                                const featureIds = results.features.map(feature => feature.attributes.ImmovableObjectID); // Replace 'name' with the actual attribute you want to extract
                                const commaSeparatedString = featureIds.join(', ');
                                sessionStorage.setItem('filterResult', commaSeparatedString);
                                filterImmovable(commaSeparatedString);
                                reload(commaSeparatedString);
                            }
                        })
                        .catch(errorCallback);

                }
                function errorCallback(error) {
                    console.log("error happened:", error.message);
                }
                worldZonePoint = new MapImageLayer({
                    url: "http://memkvidreoba.gov.ge:6080/arcgis/rest/services/Culture/WorldHeritage/MapServer",
                    listMode: "hide-children",
                    title: "მსოფლიო მემკვიდრეობის ძეგლის ბუფერული ზონა",
                    visible: this.props.type === "immovable" ? true : false,
                    opacity: .7,
                    sublayers: [
                        {
                            id: 0,
                            title: " ",
                            popupTemplate: (this.props.objectID == null || this.props.mapClass === 'objectFullScreentMap') ? {
                                title: '',
                                content: immovablePopup(),

                            } : null,
                            definitionExpression: this.props.type === "immovable" ? (this.props.objectID != null ? "ImmovableObjectID in (" + this.props.objectID + ")" : expression) : "",
                        },

                    ],
                    minScale: 10000,
                });
                map.add(worldZonePoint);

                const singlerenderer = {
                    type: "unique-value",
                    field: "Variety",
                    field2: "Status",
                    field3: "Category",
                    fieldDelimiter: ", ",
                    defaultSymbol: {
                        type: "picture-marker",
                        url: "/mapicons/red.svg",
                        width: "24px",
                        height: "24px"
                    },
                    uniqueValueInfos: [
                        {
                            value: "1, 1, 1",
                            symbol: this.getSymbol(1, Color.StatusisGareshe),
                            label: "არქეოლოგიური"
                        },
                        {
                            value: "2, 1, 1",
                            symbol: this.getSymbol(2, Color.StatusisGareshe),
                            label: "არქიტექტურის"
                        },
                        {
                            value: "3, 1, 1",
                            symbol: this.getSymbol(3, Color.StatusisGareshe),
                            label: "საინჟინრო"
                        },
                        {
                            value: "4, 1, 1",
                            symbol: this.getSymbol(4, Color.StatusisGareshe),
                            label: "ქალაქთმშენებლობის (ურბანული)"
                        },
                        {
                            value: "5, 1, 1",
                            symbol: this.getSymbol(5, Color.StatusisGareshe),
                            label: "საბაღე-საპარკო ხელოვნებისა და ლანდშაფტური არქიტექტურის"
                        },
                        {
                            value: "6, 1, 1",
                            symbol: this.getSymbol(6, Color.StatusisGareshe),
                            label: "პალეოგრაფიული"
                        },
                        {
                            value: "7, 1, 1",
                            symbol: this.getSymbol(7, Color.StatusisGareshe),
                            label: "მონუმენტური სახვითი ხელოვნების"
                        },
                        {
                            value: "8, 1, 1",
                            symbol: this.getSymbol(8, Color.StatusisGareshe),
                            label: "მემორიალური"
                        },
                        {
                            value: "9, 1, 1",
                            symbol: this.getSymbol(9, Color.StatusisGareshe),
                            label: "ეთნოგრაფიული"
                        },
                        {
                            value: "10, 1, 1",
                            symbol: this.getSymbol(10, Color.StatusisGareshe),
                            label: "სახვითი ხელოვნების"
                        },
                        {
                            value: "11, 1, 1",
                            symbol: this.getSymbol(11, Color.StatusisGareshe),
                            label: "მეცნიერების, ტექნიკის ან მრეწველობის განვითარებასთან დაკავშირებული"
                        },
                        {
                            value: "1, 2, 1",
                            symbol: this.getSymbol(1, Color.Obieqti),
                            label: "არქეოლოგიური"
                        },
                        {
                            value: "2, 2, 1",
                            symbol: this.getSymbol(2, Color.Obieqti),
                            label: "არქიტექტურის"
                        },
                        {
                            value: "3, 2, 1",
                            symbol: this.getSymbol(3, Color.Obieqti),
                            label: "საინჟინრო"
                        },
                        {
                            value: "4, 2, 1",
                            symbol: this.getSymbol(4, Color.Obieqti),
                            label: "ქალაქთმშენებლობის (ურბანული)"
                        },
                        {
                            value: "5, 2, 1",
                            symbol: this.getSymbol(5, Color.Obieqti),
                            label: "საბაღე-საპარკო ხელოვნებისა და ლანდშაფტური არქიტექტურის"
                        },
                        {
                            value: "6, 2, 1",
                            symbol: this.getSymbol(6, Color.Obieqti),
                            label: "პალეოგრაფიული"
                        },
                        {
                            value: "7, 2, 1",
                            symbol: this.getSymbol(7, Color.Obieqti),
                            label: "მონუმენტური სახვითი ხელოვნების"
                        },
                        {
                            value: "8, 2, 1",
                            symbol: this.getSymbol(8, Color.Obieqti),
                            label: "მემორიალური"
                        },
                        {
                            value: "9, 2, 1",
                            symbol: this.getSymbol(9, Color.Obieqti),
                            label: "ეთნოგრაფიული"
                        },
                        {
                            value: "10, 2, 1",
                            symbol: this.getSymbol(10, Color.Obieqti),
                            label: "სახვითი ხელოვნების"
                        },
                        {
                            value: "11, 2, 1",
                            symbol: this.getSymbol(11, Color.Obieqti),
                            label: "მეცნიერების, ტექნიკის ან მრეწველობის განვითარებასთან დაკავშირებული"
                        },
                        {
                            value: "1, 3, 1",
                            symbol: this.getSymbol(1, Color.Zegli),
                            label: "არქეოლოგიური"
                        },
                        {
                            value: "2, 3, 1",
                            symbol: this.getSymbol(2, Color.Zegli),
                            label: "არქიტექტურის"
                        },
                        {
                            value: "3, 3, 1",
                            symbol: this.getSymbol(3, Color.Zegli),
                            label: "საინჟინრო"
                        },
                        {
                            value: "4, 3, 1",
                            symbol: this.getSymbol(4, Color.Zegli),
                            label: "ქალაქთმშენებლობის (ურბანული)"
                        },
                        {
                            value: "5, 3, 1",
                            symbol: this.getSymbol(5, Color.Zegli),
                            label: "საბაღე-საპარკო ხელოვნებისა და ლანდშაფტური არქიტექტურის"
                        },
                        {
                            value: "6, 3, 1",
                            symbol: this.getSymbol(6, Color.Zegli),
                            label: "პალეოგრაფიული"
                        },
                        {
                            value: "7, 3, 1",
                            symbol: this.getSymbol(7, Color.Zegli),
                            label: "მონუმენტური სახვითი ხელოვნების"
                        },
                        {
                            value: "8, 3, 1",
                            symbol: this.getSymbol(8, Color.Zegli),
                            label: "მემორიალური"
                        },
                        {
                            value: "9, 3, 1",
                            symbol: this.getSymbol(9, Color.Zegli),
                            label: "ეთნოგრაფიული"
                        },
                        {
                            value: "10, 3, 1",
                            symbol: this.getSymbol(10, Color.Zegli),
                            label: "სახვითი ხელოვნების"
                        },
                        {
                            value: "11, 3, 1",
                            symbol: this.getSymbol(11, Color.Zegli),
                            label: "მეცნიერების, ტექნიკის ან მრეწველობის განვითარებასთან დაკავშირებული"
                        },
                        {
                            value: "1, 3, 5",
                            symbol: this.getSymbol(1, Color.ErovnuliZegli),
                            label: "არქეოლოგიური"
                        },
                        {
                            value: "2, 3, 5",
                            symbol: this.getSymbol(2, Color.ErovnuliZegli),
                            label: "არქიტექტურის"
                        },
                        {
                            value: "3, 3, 5",
                            symbol: this.getSymbol(3, Color.ErovnuliZegli),
                            label: "საინჟინრო"
                        },
                        {
                            value: "4, 3, 5",
                            symbol: this.getSymbol(4, Color.ErovnuliZegli),
                            label: "ქალაქთმშენებლობის (ურბანული)"
                        },
                        {
                            value: "5, 3, 5",
                            symbol: this.getSymbol(5, Color.ErovnuliZegli),
                            label: "საბაღე-საპარკო ხელოვნებისა და ლანდშაფტური არქიტექტურის"
                        },
                        {
                            value: "6, 3, 5",
                            symbol: this.getSymbol(6, Color.ErovnuliZegli),
                            label: "პალეოგრაფიული"
                        },
                        {
                            value: "7, 3, 5",
                            symbol: this.getSymbol(7, Color.ErovnuliZegli),
                            label: "მონუმენტური სახვითი ხელოვნების"
                        },
                        {
                            value: "8, 3, 5",
                            symbol: this.getSymbol(8, Color.ErovnuliZegli),
                            label: "მემორიალური"
                        },
                        {
                            value: "9, 3, 5",
                            symbol: this.getSymbol(9, Color.ErovnuliZegli),
                            label: "ეთნოგრაფიული"
                        },
                        {
                            value: "10, 3, 5",
                            symbol: this.getSymbol(10, Color.ErovnuliZegli),
                            label: "სახვითი ხელოვნების"
                        },
                        {
                            value: "11, 3, 5",
                            symbol: this.getSymbol(11, Color.ErovnuliZegli),
                            label: "მეცნიერების, ტექნიკის ან მრეწველობის განვითარებასთან დაკავშირებული"
                        }, {
                            value: "1, 3, 6",
                            symbol: this.getSymbol(1, Color.MsoflioZegli),
                            label: "არქეოლოგიური"
                        },
                        {
                            value: "2, 3, 6",
                            symbol: this.getSymbol(2, Color.MsoflioZegli),
                            label: "არქიტექტურის"
                        },
                        {
                            value: "3, 3, 6",
                            symbol: this.getSymbol(3, Color.MsoflioZegli),
                            label: "საინჟინრო"
                        },
                        {
                            value: "4, 3, 6",
                            symbol: this.getSymbol(4, Color.MsoflioZegli),
                            label: "ქალაქთმშენებლობის (ურბანული)"
                        },
                        {
                            value: "5, 3, 6",
                            symbol: this.getSymbol(5, Color.MsoflioZegli),
                            label: "საბაღე-საპარკო ხელოვნებისა და ლანდშაფტური არქიტექტურის"
                        },
                        {
                            value: "6, 3, 6",
                            symbol: this.getSymbol(6, Color.MsoflioZegli),
                            label: "პალეოგრაფიული"
                        },
                        {
                            value: "7, 3, 6",
                            symbol: this.getSymbol(7, Color.MsoflioZegli),
                            label: "მონუმენტური სახვითი ხელოვნების"
                        },
                        {
                            value: "8, 3, 6",
                            symbol: this.getSymbol(8, Color.MsoflioZegli),
                            label: "მემორიალური"
                        },
                        {
                            value: "9, 3, 6",
                            symbol: this.getSymbol(9, Color.MsoflioZegli),
                            label: "ეთნოგრაფიული"
                        },
                        {
                            value: "10, 3, 6",
                            symbol: this.getSymbol(10, Color.MsoflioZegli),
                            label: "სახვითი ხელოვნების"
                        },
                        {
                            value: "11, 3, 6",
                            symbol: this.getSymbol(11, Color.MsoflioZegli),
                            label: "მეცნიერების, ტექნიკის ან მრეწველობის განვითარებასთან დაკავშირებული"
                        }

                    ]
                };

                const defaultRenderer = {
                    type: "unique-value",
                    field: "Status",
                    field2: "Category",
                    fieldDelimiter: ", ",
                    defaultSymbol: {
                        type: "simple-marker",
                        size: 6,
                        color: "rgba(147, 25, 40, 1)",
                        outline: {
                            color: "rgba(0, 0, 0, 1)",
                            width: 1
                        }
                    },
                    uniqueValueInfos: [
                        {
                            value: "1, 1",
                            symbol: {
                                type: "simple-marker",
                                size: 6,
                                color: "rgba(147, 25, 40, 1)",
                                outline: {
                                    color: "rgba(0, 0, 0, 1)",
                                    width: 1
                                }
                            },
                            label: "არქეოლოგიური"
                        },
                        {
                            value: "2, 1",
                            symbol: {
                                type: "simple-marker",
                                size: 6,
                                color: "rgba(147, 25, 40, 1)",
                                outline: {
                                    color: "rgba(0, 0, 0, 1)",
                                    width: 1
                                }
                            },
                            label: "არქეოლოგიური"
                        },
                        {
                            value: "3, 1",
                            symbol: {
                                type: "simple-marker",
                                size: 6,
                                color: "rgba(221, 73, 171, 1)",
                                outline: {
                                    color: "rgba(0, 0, 0, 1)",
                                    width: 1
                                }
                            },
                            label: "არქეოლოგიური"
                        },
                        {
                            value: "3, 5",
                            symbol: {
                                type: "simple-marker",
                                size: 6,
                                color: "rgba(255, 0, 169, 1)",
                                outline: {
                                    color: "rgba(0, 0, 0, 1)",
                                    width: 1
                                }
                            },
                            label: "არქეოლოგიური"
                        },
                        {
                            value: "3, 6",
                            symbol: {
                                type: "simple-marker",
                                size: 6,
                                color: "rgba(245, 166, 35, 1)",
                                outline: {
                                    color: "rgba(0, 0, 0, 1)",
                                    width: 1
                                }
                            },
                            label: "არქეოლოგიური"
                        }
                    ]
                };


                const clusterConfig = {
                    type: "cluster",
                    clusterRadius: "50px",
                    clusterMinSize: "22px",
                    clusterMaxSize: "60px",
                    labelingInfo: [
                        {
                            deconflictionStrategy: "none",
                            labelExpressionInfo: {
                                expression: "Text($feature.cluster_count, '#,###')"
                            },
                            symbol: {
                                type: "text",
                                color: "#ffffff",
                                font: {
                                    weight: "bold",
                                    family: "Noto Sans",
                                    size: "12px"
                                }
                            },
                            labelPlacement: "center-center"
                        }
                    ],

                };

                const renderer = {
                    type: "simple",  // autocasts as new SimpleRenderer()
                    symbol: {
                        type: "simple-marker",
                        size: 4,
                        color: "rgba(229, 121, 89, 0.7)",
                        outline: {
                            color: "rgba(229, 121, 89, 0.3)",
                            width: 4
                        }
                    }
                };

                //const renderer = {
                //    type: "unique-value",
                //    field: "cluster_count",
                //    valueExpression: "When($feature.cluster_count > 1, 'group',  $feature.forCluster)",
                //    defaultSymbol: {
                //        type: "simple-marker",
                //            size: 4,
                //            color: "rgba(229, 121, 89, 0.7)",
                //            outline: {
                //                color: "rgba(229, 121, 89, 0.3)",
                //                width: 4
                //            }                       
                //    },
                //    uniqueValueInfos: [
                //        {
                //            value: "1, 1",
                //            symbol: {
                //                type: "simple-marker",
                //                size: 6,
                //                color: "rgba(147, 25, 40, 1)",
                //                outline: {
                //                    color: "rgba(0, 0, 0, 1)",
                //                    width: 1
                //                }
                //            },
                //            label: "არქეოლოგიური"
                //        },
                //        {
                //            value: "2, 1",
                //            symbol: {
                //                type: "simple-marker",
                //                size: 6,
                //                color: "rgba(147, 25, 40, 1)",
                //                outline: {
                //                    color: "rgba(0, 0, 0, 1)",
                //                    width: 1
                //                }
                //            },
                //            label: "არქეოლოგიური"
                //        },
                //        {
                //            value: "3, 1",
                //            symbol: {
                //                type: "simple-marker",
                //                size: 6,
                //                color: "rgba(221, 73, 171, 1)",
                //                outline: {
                //                    color: "rgba(0, 0, 0, 1)",
                //                    width: 1
                //                }
                //            },
                //            label: "არქეოლოგიური"
                //        },
                //        {
                //            value: "3, 5",
                //            symbol: {
                //                type: "simple-marker",
                //                size: 6,
                //                color: "rgba(255, 0, 169, 1)",
                //                outline: {
                //                    color: "rgba(0, 0, 0, 1)",
                //                    width: 1
                //                }
                //            },
                //            label: "არქეოლოგიური"
                //        },
                //        {
                //            value: "3, 6",
                //            symbol: {
                //                type: "simple-marker",
                //                size: 6,
                //                color: "rgba(245, 166, 35, 1)",
                //                outline: {
                //                    color: "rgba(0, 0, 0, 1)",
                //                    width: 1
                //                }
                //            },
                //            label: "არქეოლოგიური"
                //        },
                //        {
                //            value: 'group',
                //            symbol: {
                //                type: "simple-marker",
                //                size: 4,
                //                color: "rgba(229, 121, 89, 0.7)",
                //                outline: {
                //                    color: "rgba(229, 121, 89, 0.3)",
                //                    width: 4
                //                }
                //            },
                //            label: "Interstate"
                //        },
                //    ]
                //};


                Flayer = new FeatureLayer({
                    url: "http://memkvidreoba.gov.ge:6080/arcgis/rest/services/Culture/TestPoints/MapServer/0",
                    title: "ძეგლი/ობიექტი",
                    outFields: ["ObjectName", "forCluster", "PhotoAddress", "ImmovableObjectID", "VarietyName"],
                    visible: this.props.type === "immovable" ? true : false,
                    renderer: renderer,
                    // renderer: defaultRenderer,
                    popupTemplate: (this.props.objectID == null || this.props.mapClass === 'objectFullScreentMap') ? {
                        title: '',
                        content: immovablePopup(),

                    } : null,
                    definitionExpression: this.props.type === "immovable" ? (this.props.objectID != null ? "ImmovableObjectID in (" + this.props.objectID + ")" : expression) : "",
                    featureReduction: clusterConfig
                    //  screenSizePerspectiveEnabled: true
                });

                map.add(Flayer);

                if (this.props.type === "immovable" && this.props.objectID != null) {

                    qTask = new QueryTask({
                        url: "http://memkvidreoba.gov.ge:6080/arcgis/rest/services/Culture/TestPoints/MapServer/0"
                    });

                    params = new Query({
                        returnGeometry: false,
                        outFields: ["ImmovableObjectID", "X", "Y"]

                    });
                    params.where = "ImmovableObjectID in ( " + this.props.objectID + ")";
                    qTask.execute(params).then(getResults.bind(this)).catch();
                }

                // ----------------------museum --------------------------

                filterMuseum = function filterMuseum(fr) {
                    var r = fr || '';
                    var loc = sessionStorage.getItem('locationfilterResult') || '';

                    var array1 = r !== '' ? r.split(',') : [];
                    var array2 = loc !== '' ? loc.split(',') : [];


                    var filterResult = array1.length > 0 ? array1.filter(value => array2.includes(value) || array2.length === 0) : array2

                    if (array2.length > 0 && filterResult.length === 0) filterResult = ['0'];

                    filterResult = filterResult.join(',');

                    var expression = filterResult === '' ? '' : "MuseumID in (" + filterResult + ")";

                    museumLayer.findSublayerById(8).definitionExpression = expression;
                    museumPointLayer.findSublayerById(7).definitionExpression = expression;
                }


                if (this.props.type === "museum") {
                    ///filterResult = sessionStorage.getItem('filterResult') || '';

                    r = sessionStorage.getItem('filterResult') || '';
                    loc = sessionStorage.getItem('locationfilterResult') || '';

                    array1 = r !== '' ? r.split(',') : [];
                    array2 = loc !== '' ? loc.split(',') : [];

                    filterResult = array1.length > 0 ? array1.filter(value => array2.includes(value) || array2.length === 0) : array2

                    if (array2.length > 0 && filterResult.length === 0) filterResult = ['0'];

                    filterResult = filterResult.join(',');

                    expression = filterResult === '' ? '' : "MuseumID in (" + filterResult + ")";
                }
                var museumLayer = new MapImageLayer({
                    url: "http://memkvidreoba.gov.ge:6080/arcgis/rest/services/Culture/CulturePortalService/MapServer",
                    listMode: "hide-children",
                    title: "მუზეუმ-ნაკრძალი",
                    visible: this.props.type === "museum" ? true : false,
                    opacity: .8,
                    sublayers: [
                        {
                            title: " ",
                            id: 8,

                            popupTemplate: this.props.objectID == null ? {
                                title: "",
                                outFields: ["MuseumID", "PhotoAddress", "Name"],
                                content: museumPopup
                            } : null,
                            definitionExpression: this.props.type === "museum" ? (this.props.objectID != null ? "MuseumID in (" + this.props.objectID + ")" : expression) : ""
                        },

                    ]
                });
                map.add(museumLayer);

                var museumPointLayer = new MapImageLayer({
                    url: "http://memkvidreoba.gov.ge:6080/arcgis/rest/services/Culture/CulturePortalService/MapServer",
                    listMode: "hide-children",
                    title: "მუზეუმი",
                    visible: this.props.type === "museum" ? true : false,
                    sublayers: [
                        {
                            title: " ",
                            id: 7,
                            popupTemplate: this.props.objectID == null ? {
                                title: "",
                                outFields: ["MuseumID", "PhotoAddress", "Name"],
                                content: museumPopup
                            } : null,
                            definitionExpression: this.props.type === "museum" ? (this.props.objectID != null ? "MuseumID in (" + this.props.objectID + ")" : expression) : ""
                        },

                    ]
                });
                map.add(museumPointLayer);

                if (this.props.type === "museum" && this.props.objectID != null) {
                    qTask = new QueryTask({
                        url: "http://memkvidreoba.gov.ge:6080/arcgis/rest/services/Culture/CulturePortalService/MapServer/7"
                    });

                    params = new Query({
                        returnGeometry: false,
                        outFields: ["X", "Y"]

                    });
                    params.where = "MuseumID in (" + this.props.objectID + ")";
                    qTask.execute(params).then(getMuseumResults.bind(this)).catch();
                }
                function getMuseumResults(response) {
                    if (response != null && response.features != null) {
                        var peakResults = response.features.map(function (feature) {
                            var pointGraphic = new Graphic({
                                geometry: {
                                    type: "point",
                                    longitude: feature.attributes.X,
                                    latitude: feature.attributes.Y,
                                },
                                symbol: {
                                    type: "simple-marker",  // autocasts as new SimpleMarkerSymbol()
                                    style: "circle",
                                    color: "transparent",
                                    size: "26px",  // pixels
                                    outline: {  // autocasts as new SimpleLineSymbol()
                                        color: [255, 255, 2550],
                                        width: 3  // points
                                    }
                                }
                            });
                            return pointGraphic;
                        }, this);
                        selectionLayer.addMany(peakResults);
                    }

                    if (response.features.length > 0) {
                        view.center = [response.features[0].attributes.X, response.features[0].attributes.Y];  // Sets the center point of the view at a specified lon/lat
                        view.zoom = 15;
                    }
                }

                if (this.props.type === "museum" && this.props.objectID != null) {
                    qTask = new QueryTask({
                        url: "http://memkvidreoba.gov.ge:6080/arcgis/rest/services/Culture/CulturePortalService/MapServer/8"
                    });

                    params = new Query({
                        returnGeometry: true,
                        outFields: ["OBJECTID "]
                    });
                    params.where = "MuseumID in (" + this.props.objectID + ")";
                    qTask.execute(params).then(getMuseumPolyResults.bind(this)).catch();
                }
                function getMuseumPolyResults(response) {
                    if (response != null && response.features != null) {
                        var peakResults = response.features.map(function (feature) {
                            var pointGraphic = new Graphic({
                                geometry: response.features[0].geometry,
                                symbol: {
                                    type: "simple-fill",  // autocasts as new SimpleFillSymbol()
                                    color: "transparent",
                                    style: "solid",
                                    outline: {  // autocasts as new SimpleLineSymbol()
                                        color: "white",
                                        width: 3
                                    }
                                }
                            });
                            return pointGraphic;
                        }, this);
                        selectionLayer.addMany(peakResults);
                    }

                    if (response.features.length > 0) {
                        var r = webMercatorUtils.xyToLngLat(response.features[0].geometry.center.x, response.features[0].geometry.center.y)
                        view.center = [r[0], r[1]];  // Sets the center point of the view at a specified lon/lat
                        view.zoom = 15;
                    }
                }


                //---------------------all
                if (this.props.type === "all") {
                    filterResult = sessionStorage.getItem('filterResult') || '';
                    filterMap(this.props.type, filterResult);
                }
                //---------------------movable
                if (this.props.type === "movable") {
                    filterResult = sessionStorage.getItem('filterResult') || '';

                    filterMap(this.props.type, filterResult);
                }

                //---------------------museum
                if (this.props.type === "museum" && this.props.objectID == null) {
                    // filterResult = sessionStorage.getItem('filterResult') || '';

                    r = sessionStorage.getItem('filterResult') || '';
                    loc = sessionStorage.getItem('locationfilterResult') || '';

                    array1 = r !== '' ? r.split(',') : [];
                    array2 = loc !== '' ? loc.split(',') : [];

                    filterResult = array1.length > 0 ? array1.filter(value => array2.includes(value) || array2.length === 0) : array2

                    if (array2.length > 0 && filterResult.length === 0) filterResult = ['0'];

                    filterResult = filterResult.join(',');

                    filterMap(this.props.type, filterResult);
                }
                //---------------------wallart
                if (this.props.type === "wallart") {
                    filterResult = sessionStorage.getItem('filterResult') || '';

                    filterMap(this.props.type, filterResult);
                }
                //---------------------stoneengrave
                if (this.props.type === "stoneengrave") {
                    filterResult = sessionStorage.getItem('filterResult') || '';

                    filterMap(this.props.type, filterResult);
                }
                //---------------------event
                if (this.props.type === "event") {
                    filterResult = this.props.objectID == null ? sessionStorage.getItem('filterResult') || '' : this.props.objectID;

                    filterMap(this.props.type, filterResult);
                }

                if (this.props.type === "immovable" && this.props.objectID == null) {
                    // filterResult = sessionStorage.getItem('filterResult') || '';

                    r = sessionStorage.getItem('filterResult') || '';
                    loc = sessionStorage.getItem('locationfilterResult') || '';

                    array1 = r !== '' ? r.split(',') : [];
                    array2 = loc !== '' ? loc.split(',') : [];

                    filterResult = array1.length > 0 ? array1.filter(value => array2.includes(value) || array2.length === 0) : array2

                    if (array2.length > 0 && filterResult.length === 0) filterResult = ['0'];
                    filterResult = filterResult.join(',');

                    if (filterResult == null || filterResult === '') {
                        var p = new URLSearchParams(window.location.search);
                        var fileter = p.get('filterText') || '';

                        if (fileter != null && fileter !== '') {
                            filterMap("text", "");
                        }
                    }
                }

                if (this.props.objToZoom != null) {
                    zoomToMapObject(this.props.type, this.props.objToZoom)
                }

                watchUtils.watch(objectfullscreen.viewModel, "state", (value) => {
                    if (value === "active") {
                        if (view.ui.find('expandBtn') == null) {
                            view.ui.add(expandBtn, "bottom-right");
                            view.ui.add(expandSketchViewModelBtn, "bottom-right");
                            view.ui.move("zoom", "bottom-right");

                            //var el = document.getElementById('menuDiv').innerHTML;

                            //document.getElementById('menuDiv').remove(el);
                            //document.getElementById('forElements').innerHTML+=el;
                        }
                    }
                    else {
                        if (view.ui.find('expandBtn') != null)
                            view.ui.remove(expandBtn);
                        if (view.ui.find('expandSketchViewModelBtn') != null)
                            view.ui.remove(expandSketchViewModelBtn);
                    }
                });


                watchUtils.watch(measurement.viewModel, "state", (value) => {

                    if (value === "measuring") {
                        if (measurement.activeWidget.messages != null) {
                            measurement.activeWidget.messages.distance = "მანძილი:"
                            measurement.activeWidget.messages.area = "ფართობი:"
                            measurement.activeWidget.messages.perimeter = "პერიმეტრი:"

                        }

                        if (measurement.viewModel.activeViewModel.tool.messages != null) {
                            measurement.viewModel.activeViewModel.tool.messages.units.kilometers.abbr = 'კმ';
                            measurement.viewModel.activeViewModel.tool.messages.units.meters.abbr = 'მ';
                            measurement.viewModel.activeViewModel.tool.messages.units['square-kilometers'].abbr = 'კმ²';
                            measurement.viewModel.activeViewModel.tool.messages.units['square-meters'].abbr = 'მ²';
                        }
                    }

                    var symbol = {
                        type: "simple-line",
                        color: "#E57959",
                        width: "4px",
                        style: "solid"
                    };

                    if (view.map.layers != null && view.map.layers.items.length > 5) {
                        //if (view.activeTool != null && view.activeTool.messages != null) {
                        //    view.activeTool.messages.units.kilometers.abbr = 'კმ';
                        //    view.activeTool.messages.units['square-meters'].abbr = 'მ²';
                        //    view.activeTool.messages.units['meters'].abbr = 'მ';
                        //}

                        var layer = view.map.layers.items[5];
                        if (layer.layers != null && layer.layers.items[0].graphics.items.length === 3) {
                            layer.layers.items[0].graphics.items[0].symbol = symbol;
                            layer.layers.items[0].graphics.items[1].visible = false;
                            layer.layers.items[0].graphics.items[2].visible = false;
                        }

                    }
                });


                view.when(function () {

                    track = new Track({
                        graphic: new Graphic({
                            symbol: {
                                type: "picture-marker",
                                url: "/mapicons/trackmylocation2.png",
                                size: "128px"
                            }
                        }),
                        //graphic: new Graphic({
                        //    symbol: {
                        //        type: "simple-marker",
                        //        size: "22px",
                        //        color: "#FFFFFF", 
                        //        style: "solid",
                        //        outline: {
                        //            color: "#33C1FF",
                        //            width: 10, // Set the width of the border here
                        //            style: "solid" // Specify the style of the border, if needed
                        //        }
                        //    }
                        //}),
                        view: view,
                        goToLocationEnabled: false,
                        scale: 10000
                    });

                    zoomSet = false;

                    if (mapClass != null) {
                        if (mapClass !== '') {
                            document.getElementsByClassName('webmap')[0].classList.add('objectFullScreentMap');
                        }
                        else {
                            document.getElementsByClassName('webmap')[0].classList.remove('objectFullScreentMap');
                        }
                    }

                    track.on("track", function (trackEvent) {

                        if (zoomSet === false && shoMyLocationSet == false) {
                            view.center = [trackEvent.position.coords.longitude, trackEvent.position.coords.latitude];
                            view.zoom = 15;
                            zoomSet = true;

                            if (sessionStorage.getItem('useLocationFilter') == null || sessionStorage.getItem('useLocationFilter') !== "false") {

                                var radius = sessionStorage.getItem('locationRadius') == null ? 5 : sessionStorage.getItem('locationRadius');
                                var geometryService = new GeometryService("http://memkvidreoba.gov.ge:6080/arcgis/rest/services/Utilities/Geometry/GeometryServer");
                                var params = new BufferParameters({
                                    distances: [radius],
                                    unit: "kilometers",
                                    geodesic: true,
                                    bufferSpatialReference: new SpatialReference({ "wkid": 32638 }),
                                    outSpatialReference: new SpatialReference({ "wkid": 32638 }),
                                    geometries: track.graphic.geometry
                                });

                                if (propsType === "immovable") {
                                    geometryService.buffer(params).then(function (results) {
                                        var qTask = new QueryTask({
                                            url: "http://memkvidreoba.gov.ge:6080/arcgis/rest/services/Culture/CulturePortalService/MapServer/4"
                                        });
                                        var params = new Query({
                                            returnGeometry: false,
                                            outFields: ["ImmovableObjectID"],
                                            geometry: results[0]
                                        });
                                        params.where = "CoordinatSizuste = N'სწორია'";
                                        qTask.execute(params).then(locationFilter.bind(this)).catch();
                                    });
                                }

                                if (propsType === "museum") {
                                    geometryService.buffer(params).then(function (results) {
                                        var qTask = new QueryTask({
                                            url: "http://memkvidreoba.gov.ge:6080/arcgis/rest/services/Culture/CulturePortalService/MapServer/7"
                                        });
                                        var params = new Query({
                                            returnGeometry: false,
                                            outFields: ["MuseumID"],
                                            geometry: results[0]
                                        });
                                        //params.where = "CoordinatSizuste = N'სწორია'";
                                        qTask.execute(params).then(museumlocationFilter.bind(this)).catch();
                                    });

                                }

                            }

                            sessionStorage.setItem('locationFilter', 'on');

                            if (sessionStorage.getItem('locationFilter') === 'on') {
                                visualZoneLayer.visible = false;
                                physZoneLayer.visible = false;


                            }

                            if (document.getElementById('locationBtn') != null)
                                document.getElementById('locationBtn').classList.add('active');
                            if (document.getElementById('locationMobileMapBtn') != null)
                                document.getElementById('locationMobileMapBtn').classList.add('active');

                            if (document.getElementById('locationMapBtn') != null)
                                document.getElementById('locationMapBtn').classList.add('active');
                        }
                        else {
                            view.center = [trackEvent.position.coords.longitude, trackEvent.position.coords.latitude];
                            view.zoom = 15;
                            zoomSet = true;
                        }
                    })


                    pinBtn.addEventListener("click", function (e) {

                        tableView.current.location(e)

                        //if (sessionStorage.getItem('locationFilter') === 'on') {
                        //    e.target.classList.remove('active');
                        //    stopLoc();
                        //}
                        //else {
                        //    //e.target.classList.add('active');
                        //    //trackStart();


                        //}
                    });

                    if (sessionStorage.getItem('locationFilter') === 'on' && propsObjectID == null) {
                        pinBtn.classList.add('active');
                        trackStart();
                    }

                });




                view.watch('updating', function (evt) {
                    //if (evt === true) {
                    //    domClass.remove('loadingDiv', 'displayNone');                      
                    //} else {
                    //    domClass.add('loadingDiv', 'displayNone');                       
                    //}
                })



                view.watch("scale", function (newValue) {
                    if (newValue <= 10000) {
                        Flayer.renderer = singlerenderer;
                        Flayer.featureReduction = null;
                    }
                    else {
                        if (newValue <= 1500000) {
                            Flayer.renderer = defaultRenderer;
                            Flayer.featureReduction = null;
                        }
                        else {
                            Flayer.renderer = renderer;
                            Flayer.featureReduction = clusterConfig;
                        }

                    }
                });

                var zonePointsLayer = new GraphicsLayer({
                    "listMode": "hide"
                });
                map.add(zonePointsLayer);


                var selectionLayer = new GraphicsLayer({
                    "listMode": "hide"
                });
                map.add(selectionLayer);

                var resultsLayer = new GraphicsLayer({
                    "listMode": "hide"
                });
                map.add(resultsLayer);






                var UserObjectGraphicsLayer = new GraphicsLayer({
                    title: "test"
                });
                map.add(UserObjectGraphicsLayer);

                setPoint = function (x, y) {
                    var point = {
                        type: "point", // autocasts as /Point
                        x: x,
                        y: y,
                        spatialReference: view.spatialReference
                    };

                    var graphic = new Graphic({
                        geometry: point,
                        symbol: {
                            type: "picture-marker",  // autocasts as new PictureMarkerSymbol()
                            url: "/mapicons/mapPin.png",
                            width: "28px",
                            height: "28px"
                        }
                    });
                    UserObjectGraphicsLayer.add(graphic);
                }

                if (sessionStorage.getItem('userPoints') != null) {
                    var userPoints = sessionStorage.getItem('userPoints').split(',');
                    userPoints.map(function (item) {
                        if (item.split(';')[0] === '0' && item.split(';')[1] === '0') {

                        }
                        else {
                            setPoint(item.split(';')[0], item.split(';')[1])
                        }

                        return { x: item.split(';')[0], y: item.split(';')[1] };
                    });
                }
            });
    }

    getSymbol(variety, type) {
        var symbol;
        symbol = {
            type: "picture-marker",
            url: "/mapicons/" + variety + type + ".svg",
            width: "26px",
            height: "26px"
        };
        return symbol;
    }


    getSelectionSymbol() {
        var symbol;
        symbol = {

            type: "simple-marker",  // autocasts as new SimpleMarkerSymbol()
            style: "circle",
            color: "blue",
            size: "26px",  // pixels
            outline: {  // autocasts as new SimpleLineSymbol()
                color: [255, 255, 0],
                width: 3  // points
            }
        };
        return symbol;
    }

    getGraphicPopup(photo, name, variety, id) {
        return (
            "<div style='width:250px;max-height:280px'>" +
            "<div style='width:250px;height:180px;background-color:#F6BD60;'><img variant='top' src='" + photo + "' alt='' /></div>" +
            "<a href='" + window.location.origin + "/objects/immovable/immovableObject?id=" + id + "'><div style='background-color:#F6BD60;font-family:FiraGO_book;font-size:15px;line-height: 21px;padding:10px'>" +
            "<div style='color: #000000;'>" + name + "</div>" +
            "<div style='color: #636363;font-size:12px;'>" + variety + "</div></div></a>" +
            "</div>"
        );


    }

    componentWillUnmount() {
        if (this.view) {
            // destroy the map view
            this.view.container = null;
        }

    }

    location(el) {
        this.props.tableView.current.location(el)

        //if (sessionStorage.getItem('locationFilter') === 'on') {
        //    el.target.classList.remove('active');
        //    stopLoc();
        //}       
        //else {
        //    //el.target.classList.add('active');
        //    //filterLoc();
        //    this.setState({ showLocation: true })
        //}

    }



    hideLocationModal() {
        this.props.tableView.current.hideLocationModal()

        //this.setState({ showLocation: false })
    }


    locationClick() {
        this.props.tableView.current.hideLocationModal()
        //filterLoc();
        //this.setState({ showLocation: false })
    }

    render() {
        const params = new URLSearchParams(window.location.search);
        var forType = params.get('t') || '';
        return (
            <div className={this.props.className} id="forElements">



                <div style={{ paddingBottom: '1px' }} className="webmap" ref={this.mapRef} >
                    {
                        //<ReactTooltip id="locationToolTip" backgroundColor="#E57959" effect='solid'>
                        //    <span>
                        //        chemtan axlos
                        //</span>
                        //</ReactTooltip>
                    }

                </div>


                <div id="toolbarDiv" className={`esri-component esri-widget displayNone ${this.props.mapClass != null && this.props.mapClass !== '' ? 'objectMapTollBar' : ''}`} >

                    <button
                        id="close"
                        className="toolbarIcon esri-widget--button esri-interactive esri-icon-close"
                        data-tooltip="დახურვა"
                    ></button>
                    <button
                        id="clear"
                        className="toolbarIcon esri-widget--button esri-interactive esri-icon-erase"
                        data-tooltip="მონაცემების გასუფთავება"
                    ></button>

                    <button
                        id="area"
                        className="toolbarIcon esri-widget--button esri-interactive esri-icon-measure-area"
                        data-tooltip="ფართობის მზომი"
                    ></button>


                    <button
                        id="distance"
                        className="toolbarIcon esri-widget--button esri-interactive  esri-icon-measure-line"
                        data-tooltip="მანძილის მზომი"
                    ></button>

                </div>
                <div id="toolbarSketchViewModelDiv" className={`esri-component esri-widget displayNone ${this.props.mapClass != null && this.props.mapClass !== '' ? 'objectMapTollBar' : ''}`} >

                    <button
                        id="closeSketchViewModel"
                        className="toolbarIcon esri-widget--button esri-interactive esri-icon-close"
                        data-tooltip="დახურვა"
                    ></button>
                    <button
                        id="clearBtn"
                        className="toolbarIcon esri-widget--button esri-interactive esri-icon-erase"
                        data-tooltip="გასუფთავება"
                    ></button>

                    <button
                        id="filterBtn"
                        className="toolbarIcon esri-widget--button esri-interactive esri-icon-filter"
                        data-tooltip="გაფილტვრა"
                    ></button>


                    <button
                        id="polygonBtn"
                        className="toolbarIcon esri-widget--button esri-interactive  esri-icon-polygon"
                        data-tooltip="მონიშნეთ სივრცე"
                    ></button>

                </div>

                <div id="showMyLocationDiv" className="esri-widget--button esricutomButton esri-icon-locate" data-tooltip="ჩემი ლოკაცია"></div>

                {/*<div id="margin-Container">*/}
                {/*    <calcite-panel id="propPanel">*/}
                {/*        <calcite-block id="headingBlock">*/}
                {/*            <calcite-action icon="polygon" title="მონიშნეთ სივრცე" scale="s" slot="control" id="polygonBtn"></calcite-action>*/}
                {/*            <calcite-action icon="filter" title="გაფილტვრა" scale="s" slot="control" id="filterBtn"></calcite-action>*/}
                {/*            <calcite-action style={{ background: "blue" }} icon="trash" color="red" title="გასუფთავება" scale="s" slot="control" id="clearSketchBtn"></calcite-action>*/}
                {/*            <calcite-action style={{ background: "blue" }} icon="trash" color="red" title="გასუფთავება" scale="s" slot="control" id="clearBtn"></calcite-action>*/}
                {/*        </calcite-block>*/}
                {/*    </calcite-panel>*/}
                {/*</div>*/}
                <div id="sketchViewModelDiv" className="esri-widget--button esricutomButton esri-icon-polygon displayNone"
                    onClick={this.showSketchViewModelMeasurement.bind(this)} data-tooltip="პოლიგონით მონიშვნა"></div>
                <div id="menuDiv" className="esri-widget--button esricutomButton esri-icon-measure displayNone"
                    onClick={this.showMeasurement.bind(this)} data-tooltip="მანძილის და ფართობის მზომი"></div>


                <div id="measureButton"
                    className={`${this.props.mapClass != null && this.props.mapClass !== '' ? 'objectMapTollBar' : ''} esri-interactive displayNone`}>
                    {
                        //
                    }
                    <WebMapLegend
                        archZoneCheck={(this.props.type === 'all' && forType === 'zone') || this.props.type === 'zone' || this.props.type === 'event' ? true : false}
                        landZoneCheck={(this.props.type === 'all' && forType === 'zone') || this.props.type === 'zone' || this.props.type === 'event' ? true : false}
                        regZoneCheck={(this.props.type === 'all' && forType === 'zone') || this.props.type === 'zone' || this.props.type === 'event' ? true : false}
                        istganZoneCheck={(this.props.type === 'all' && forType === 'zone') || this.props.type === 'zone' || this.props.type === 'event' ? true : false}
                        worldZoneCheck={(this.props.type === 'all' && forType === 'immovable') || this.props.type === 'immovable' ? true : false}
                        visZoneCheck={(this.props.type === 'all' && forType === 'immovable') || this.props.type === 'immovable' ? true : false}
                        physZoneCheck={(this.props.type === 'all' && forType === 'immovable') || this.props.type === 'immovable' ? true : false}
                        //visZoneCheck={this.state.physZoneCheck}
                        //physZoneCheck={this.state.physZoneCheck}

                        immCheck={(this.props.type === 'all' && (forType === 'immovable' || forType === 'movable' || forType === 'wallart' || forType === 'stoneengrave'))
                            || this.props.type === 'immovable' || this.props.type === 'event'
                            || this.props.type === 'movable' || this.props.type === 'wallart' || this.props.type === 'stoneengrave' ? true : false}

                        immPointCheck={(this.props.type === 'all' && (forType === 'immovable' || forType === 'movable' || forType === 'wallart' || forType === 'stoneengrave'))
                            || this.props.type === 'immovable' || this.props.type === 'event'
                            || this.props.type === 'movable' || this.props.type === 'wallart' || this.props.type === 'stoneengrave' ? true : false}

                        museumCheck={(this.props.type === 'all' && (forType === 'museum' || forType === 'movable')) || this.props.type === 'museum' || this.props.type === 'event' || this.props.type === 'movable' || this.props.type === 'wallart' || this.props.type === 'stoneengrave' ? true : false}
                        musResCheck={(this.props.type === 'all' && forType === 'museum') || this.props.type === 'museum' ? true : false}
                        legendClass={this.props.mapClass != null && this.props.mapClass !== '' ? 'objectMapLegend' : ''}
                    ></WebMapLegend>
                </div>

                <div id="bm" className="displayNone baseMap osmImage" style={{ marginLeft: '5px' }} onClick={this.changeMapBaseMap.bind(this)} />


                {this.props.type !== "all" && this.props.tableView != null && this.props.tableView.current != null ?
                    <div className="mapTotalButton">
                        <div className="MobileObjectSearch" id="MobileObjectSearch" style={{ marginRight: '10px' }}>
                            {this.props.tableView.current.GetSearch(1)}
                        </div>
                        <div className="TotalButtons" >
                            {
                                this.props.type === "immovable" || this.props.type === "museum" ?
                                    <>
                                        <button id="locationMapBtn" className={`gotoLocButton SecondaryButtonOrange ${sessionStorage.getItem('locationFilter') === 'on' ? 'active' : ''} icon-location-outline`} onClick={this.location.bind(this)}>ჩემთან ახლოს</button>
                                        <Location showLocation={this.props.tableView.current.state.showLocation} onClick={this.locationClick.bind(this)} hideLocationModal={this.hideLocationModal.bind(this)} />
                                    </>
                                    : <></>
                            }
                            {this.props.tableView.current.GetSearch()}
                        </div>
                    </div>
                    : <></>
                }



                {this.props.children}
            </div >
        );
    }
}