import React from 'react';
import { loadModules } from 'esri-loader';
import { WebMapLegend } from '../WebMapLegend';

import { Color } from '../../_helpers/Color';



var map;
var view;
export let setPoint;




export class AddObjectMap extends React.Component {

    constructor(props) {
        super(props);
       
        this.state = {
            loading: true,
            show: false,
        }
        this.mapRef = React.createRef();
        this.updateLoadingState = this.updateLoadingState.bind(this);

    }
     

    componentDidMount() {
       
        this.loadMap();
    }

    componentDidUpdate() {
        this.loadMap();
        
    }

    updateLoadingState(value) {
        this.setState({ loading: value })
    }

   
    showMeasurement() {
        const el = document.getElementById("toolbarDiv");
        el.classList.remove('displayNone');
    }


 

    async loadMap() {
        loadModules(["esri/intl", "esri/core/urlUtils", 'esri/Map', 'esri/views/MapView', "esri/layers/MapImageLayer",
            "esri/layers/GraphicsLayer", "esri/Graphic",
            "esri/tasks/QueryTask",
            "esri/tasks/support/Query",
            "esri/geometry/support/webMercatorUtils",
            "esri/layers/FeatureLayer",           
            "esri/widgets/Measurement",
            "esri/widgets/Measurement/MeasurementViewModel", 
            "esri/widgets/Fullscreen",
            "esri/widgets/Fullscreen/FullscreenViewModel",
            "esri/views/draw/Draw", 
            "esri/widgets/Track",
            "esri/core/watchUtils"
        ], { css: true })
            .then(([intl, urlUtils, ArcGISMap, MapView, MapImageLayer,
                GraphicsLayer, Graphic, QueryTask, Query, webMercatorUtils, FeatureLayer, Measurement, MeasurementVM, 
                Fullscreen, FullscreenVM, Draw, Track, watchUtils
            ]) => {
               
                let mapClass = this.props.mapClass;
               

                map = new ArcGISMap({
                    basemap: 'hybrid'
                });

             
                view = new MapView({
                    container: this.mapRef.current,
                    map: map,
                    center: [44.801023, 41.698584],
                    zoom: 10
                });



                var graphicsLayer = new GraphicsLayer();
                map.add(graphicsLayer);

                view.ui._removeComponents(["attribution"]);
                const draw = new Draw({
                    view: view
                });

                const pinBtn = document.createElement("div");
                pinBtn.classList.add('esri-widget--button');
                pinBtn.classList.add('esricutomButton');
                pinBtn.classList.add('esri-icon-map-pin');
                pinBtn.addEventListener("click", function () {
                    enableCreatePoint();
                });

                if (this.props.view !== true) {
                    view.ui.add(pinBtn, "bottom-right");
                }

                view.ui.move("zoom", "bottom-right");

                var track = new Track({
                    view: view,
                    goToLocationEnabled: false
                });
               

                var starttrack = true;

                view.when(function () {

                    if (mapClass != null) {
                        if (mapClass !== '') {
                            document.getElementsByClassName('webmap')[0].classList.add('objectFullScreentMap');
                        }
                        else {
                            document.getElementsByClassName('webmap')[0].classList.remove('objectFullScreentMap');
                        }
                    }

                    track.on("track", function () {
                        var location = track.graphic.geometry;

                        view
                            .goTo({
                                center: location,
                                tilt: 50,
                                scale: 2500
                            })
                            .catch(function (error) {
                                if (error.name !== "AbortError") {
                                    console.error(error);
                                }
                            });

                        
                        track.stop();

                    });

                    if (starttrack)
                        track.start();
                });



                function enableCreatePoint() {
                    var action = draw.create("point");
                    graphicsLayer.removeAll();
                  
                    action.on("draw-complete", function (evt) {
                        createPointGraphic(evt.vertices[0]);
                    });
                }

                function createPointGraphic(coordinates) {

                    var point = {
                        type: "point", // autocasts as /Point
                        x: coordinates[0],
                        y: coordinates[1],
                        spatialReference: view.spatialReference
                    };

                    var graphic = new Graphic({
                        geometry: point,
                        symbol: {
                            type: "picture-marker",  // autocasts as new PictureMarkerSymbol()
                            url: "/mapicons/mapPin.png",
                            width: "28px",
                            height: "28px"
                        }
                    });
                    graphicsLayer.add(graphic);

                    var r = webMercatorUtils.xyToLngLat(coordinates[0], coordinates[1])
                    UpdateCoordinates(r[0], r[1]);
                }

                let UpdateCoordinates = (x, y) => {
                    this.props.setCoordinates(x, y);
                }

                var viewmode = this.props.view;

                setPoint = function (x, y) {

                    var point = {
                        type: "point", // autocasts as /Point
                        x: x,
                        y: y,
                        spatialReference: view.spatialReference
                    };

                    var graphic = new Graphic({
                        geometry: point,
                        symbol: {
                            type: "picture-marker",  // autocasts as new PictureMarkerSymbol()
                            url: "/mapicons/mapPin.png",
                            width: "28px",
                            height: "28px"
                        }
                    });
                    graphicsLayer.add(graphic);

                    // var r = webMercatorUtils.xyToLngLat(coordinates[0], coordinates[1])
                    if (viewmode !== true) {
                        UpdateCoordinates(x, y);
                    }
                }

                if (this.props.coordinatebi) {
                    setPoint(this.props.coordinatebi.x, this.props.coordinatebi.y);

                    view.center = [this.props.coordinatebi.x, this.props.coordinatebi.y];
                    view.zoom = 16;

                    starttrack = false;
                }





                view.ui._removeComponents(["attribution"]);

                intl.setLocale("en-US");

                window.changeBaseMap = function (value) {
                    view.map.basemap = value;
                }

                window.checkZoneVisiblity = function () {
                    return visualZoneLayer.visible;                    
                }



                const expandBtn = document.createElement("div");
                expandBtn.id = "expandBtn";
                expandBtn.classList.add('esri-widget--button');
                expandBtn.classList.add('esricutomButton');
                expandBtn.classList.add('esri-icon-handle-horizontal');

                expandBtn.setAttribute('data-tooltip', 'მენიუ');
                expandBtn.addEventListener("click", function () {
                    showMenu();
                });

                if (this.props.mobile === false) {
                    expandBtn.classList.add('mobileDisable');
                }
                view.ui.add(expandBtn, "bottom-right");
              

                view.ui.move("zoom", "bottom-right");

                var fullscreenVM = new FullscreenVM({
                    view: view
                });

                var objectfullscreen = new Fullscreen({
                    view: view,
                    viewModel: fullscreenVM
                });


                const fullScreen = document.createElement("div");
                fullScreen.classList.add('esri-widget--button');
                fullScreen.classList.add(this.props.fullScreeenIcon);
                fullScreen.onclick = this.props.fullClick;

                view.ui.add(fullScreen, "top-right");


                function showMenu() {
                    const el = document.getElementById("menuDiv");
                    if (el.classList.contains('displayNone'))
                        el.classList.remove('displayNone');
                    else el.classList.add('displayNone');
                }

              
                ///////////measure

                var measurementVM = new MeasurementVM({
                    view: view
                });

                const measurement = new Measurement({
                    view: view,
                    areaUnit: "square-meters",
                    viewModel: measurementVM
                });
                view.ui.add(measurement, "top-left");

                // view.ui.add(measurement, "bottom-left");

                const distanceButton = document.getElementById("distance");
                const areaButton = document.getElementById("area");
                const clearButton = document.getElementById("clear");
                const closeButton = document.getElementById("close");

                distanceButton.addEventListener("click", function () {
                    distanceMeasurement();
                });
                areaButton.addEventListener("click", function () {
                    areaMeasurement();
                });
                clearButton.addEventListener("click", function () {
                    clearMeasurements();
                });

                closeButton.addEventListener("click", function () {
                    closeMeasurements();
                    const el = document.getElementById("toolbarDiv");
                    el.classList.add('displayNone');
                });

                function distanceMeasurement() {
                    measurement.activeTool = "distance";
                    distanceButton.classList.add("active");
                    areaButton.classList.remove("active");
                }

                // Call the appropriate AreaMeasurement2D or AreaMeasurement3D
                function areaMeasurement() {
                    measurement.activeTool = "area";
                    distanceButton.classList.remove("active");
                    areaButton.classList.add("active");
                }

                // Clears all measurements
                function closeMeasurements() {

                    measurement.clear();
                }

                function clearMeasurements() {
                    distanceButton.classList.remove("active");
                    areaButton.classList.remove("active");
                    measurement.clear();
                }

                var proxy = "https://memkvidreoba.gov.ge/DotNet/proxy.ashx?";

                urlUtils.addProxyRule({
                    urlPrefix: "http://memkvidreoba.gov.ge:6080/arcgis/rest/services",
                    // proxyUrl: "http://memkvidreoba.gov.ge:90/DotNetProxy/proxy.ashx"
                    proxyUrl: "https://memkvidreoba.gov.ge/DotNet/proxy.ashx"

                });

             


          

       
                window.changeVisibility = function (layer, value) {

                    switch (layer) {
                        case "arch":
                            archzone.visible = value;
                            createZonePoint((zoneObjectIDS.length > 0 ? zoneObjectIDS : ''), true);
                            break;
                        case "land":
                            landzone.visible = value;
                            createZonePoint((zoneObjectIDS.length > 0 ? zoneObjectIDS : ''), true);
                            break;
                        case "reg":
                            regzone.visible = value;
                            createZonePoint((zoneObjectIDS.length > 0 ? zoneObjectIDS : ''), true);
                            break;
                        case "istgan":
                            istzone.visible = value;
                            createZonePoint((zoneObjectIDS.length > 0 ? zoneObjectIDS : ''), true);
                            break;
                        case "vis":
                            visualZoneLayer.visible = value;
                            break;
                        case "phys":
                            physZoneLayer.visible = value;
                            break;
                        case "imm":
                            immovableLayer.visible = value;
                            break;
                        case "immPoint":
                            Flayer.visible = value;
                            break;
                        case "musRes":
                            museumLayer.visible = value;
                            break;
                        case "museum":
                            museumPointLayer.visible = value;
                            break;
                        default:
                            break;
                    }
                }
                window.changeOpacity = function (layer, value) {
                    switch (layer) {
                        case "arch":
                            archzone.opacity = value;
                            break;
                        case "land":
                            landzone.opacity = value;
                            break;
                        case "reg":
                            regzone.opacity = value;
                            break;
                        case "istgan":
                            istzone.opacity = value;
                            break;
                        case "vis":
                            visualZoneLayer.opacity = value;
                            break;
                        case "phys":
                            physZoneLayer.opacity = value;
                            break;
                        case "imm":
                            immovableLayer.opacity = value;
                            break;
                        case "immPoint":
                            Flayer.opacity = value;
                            break;
                        case "musRes":
                            museumLayer.opacity = value;
                            break;
                        case "museum":
                            museumPointLayer.opacity = value;
                            break;
                        default:
                            break;
                    }
                }

                var qTask;
                var params;
                var filterResult;
                var expression;
                // ----------------------zone --------------------------

                var zoneObjectIDS = [];
                var updateObjectIDS = true;
                function drawZonePointsResults(response) {
                    if (response != null && response.features != null) {
                        var peakResults = response.features.map(function (feature) {
                            var r = webMercatorUtils.xyToLngLat(feature.geometry.centroid.x, feature.geometry.centroid.y)
                            var pointGraphic = new Graphic({
                                geometry: {
                                    type: "point",
                                    longitude: r[0],
                                    latitude: r[1],
                                },
                                symbol: {

                                    type: "picture-marker",
                                    url: "/mapicons/zone.svg",
                                    yoffset: "14px",
                                    width: "28px",
                                    height: "28px"
                                },
                                attributes: feature.attributes
                            });
                            return pointGraphic;
                        }, this);
                        zonePointsLayer.removeAll();
                        zonePointsLayer.addMany(peakResults);
                        if (updateObjectIDS === true) {
                            zoneObjectIDS = zonePointsLayer.graphics.map(function (gr) {
                                return gr.attributes.OBJECTID;
                            }, this);
                        }
                    }
                }



                function createZonePoint(filterResult, byID) {
                    var fValue = (byID === true ? 'OBJECTID' : 'ZoneID');
                    var expression = filterResult === '' ? '' : fValue + " in (" + filterResult + ")";

                    var activeZones = [];
                    if (archzone.visible === true)
                        activeZones.push(14);
                    if (regzone.visible === true)
                        activeZones.push(12);
                    if (landzone.visible === true)
                        activeZones.push(13);
                    if (istzone.visible === true)
                        activeZones.push(11);

                    if (activeZones.length === 0) activeZones.push(0);
                    var zonePointExpressiont = expression === '' ? (activeZones.length > 0 ? 'ZoneType  in (' + activeZones.join(',') + ')' : '') : expression + (activeZones.length > 0 ? ' and ZoneType  in (' + activeZones.join(',') + ')' : '');

                    qTask = new QueryTask({
                        url: "http://memkvidreoba.gov.ge:6080/arcgis/rest/services/Culture/CultureService/MapServer/4",
                    });
                    params = new Query({
                        returnGeometry: true,
                        outFields: ["PhotoAddress", "ZoneID", "Name", "ZoneType", "OBJECTID"]

                    });
                    params.where = zonePointExpressiont;
                    params.outSpatialReference = { wkid: 102100 };
                    qTask.execute(params).then(drawZonePointsResults.bind(this)).catch();

                    if (byID === true) updateObjectIDS = false;
                    else updateObjectIDS = true;

                }

              
                var archzone = new MapImageLayer({
                    url: "http://memkvidreoba.gov.ge:6080/arcgis/rest/services/Culture/CulturePortalService/MapServer",
                    listMode: "hide-children",
                    title: "არქეოლოგიური დაცვის ზონა",
                    visible: false,
                    opacity: .8,
                    sublayers: [
                        {
                            id: 0,

                            popupTemplate:  {
                                title: "",
                                outFields: ["PhotoAddress", "ZoneID", "Name"],
                                content: zonePopup
                            } ,
                            definitionExpression: ""
                        },
                    ]
                });
                map.add(archzone);

                var landzone = new MapImageLayer({
                    url: "http://memkvidreoba.gov.ge:6080/arcgis/rest/services/Culture/CulturePortalService/MapServer",
                    title: "ისტორიული ლანდშაფტის დაცვის ზონა",
                    listMode: "hide-children",
                    visible: false,
                    opacity: .8,
                    sublayers: [
                        {
                            id: 1,

                            popupTemplate:  {
                                title: "",
                                outFields: ["PhotoAddress", "ZoneID", "Name"],
                                content: zonePopup
                            } ,
                            definitionExpression: ""
                        },
                    ]
                });
                map.add(landzone);

                var regzone = new MapImageLayer({
                    url: "http://memkvidreoba.gov.ge:6080/arcgis/rest/services/Culture/CulturePortalService/MapServer",
                    listMode: "hide-children",
                    title: "განაშენიანების რეგულირების ზონა",
                    visible: false,
                    opacity: .8,
                    sublayers: [
                        {
                            id: 2,

                            popupTemplate: {
                                title: "",
                                outFields: ["PhotoAddress", "ZoneID", "Name"],
                                content: zonePopup
                            } ,
                            definitionExpression: ""
                        },
                    ]
                });
                map.add(regzone);

                var istzone = new MapImageLayer({
                    url: "http://memkvidreoba.gov.ge:6080/arcgis/rest/services/Culture/CulturePortalService/MapServer",
                    title: "ისტორიული განაშენიანების დამცავი ზონა",
                    listMode: "hide-children",
                    visible: false,
                    opacity: .8,
                    sublayers: [
                        {
                            id: 3,


                            popupTemplate:  {
                                title: "",
                                outFields: ["PhotoAddress", "ZoneID", "Name"],
                                content: zonePopup
                            } ,
                            definitionExpression: ""
                        },
                    ]
                });
                map.add(istzone);



                createZonePoint('', false);
                

                // ----------------------immovable --------------------------
                var Flayer;
                var visualZoneLayer;
                var physZoneLayer;
                var immovableLayer;

                function immovablePopup() {
                    var result =
                        "<div class='popupContent'>" +
                        "<div class='popupContentImageDiv'>" +
                        "<img src=' {PhotoAddress}' alt='' class='popupContentImage'/></div>" +
                        "<a href='" + window.location.origin + "/objects/immovable/immovableObject?id={ImmovableObjectID}'>" +
                        "<div style='background-color:#F6BD60;font-family:FiraGO_book;font-size:15px;line-height: 21px;padding:10px;cursor:pointer'>" +
                        "<div style='color:#000000;'>{ObjectName}</div>" +
                        "<div style='color:#636363;font-size:12px;'>{VarietyName}</div>" +
                        "</div></a></div>";

                    return result;
                }
                function museumPopup(feature) {
                    var result =
                        "<div class='popupContent'>" +
                        "<div class='popupContentImageDiv'><img src=' {PhotoAddress}' alt='' class='popupContentImage'/></div>" +
                        "<a href='" + window.location.origin + "/objects/museum/museumObject?id={MuseumID}'><div style='background-color:#F6BD60;font-family:FiraGO_book;font-size:15px;line-height: 21px;padding:10px;cursor:pointer'>" +
                        "<div style='color: #000000;'>{Name}</div>" +
                        "</div></a>" +
                        "</div>";
                    return result;
                }
                function zonePopup(feature) {
                    var result =
                        "<div class='popupContent'>" +
                        "<div class='popupContentImageDiv'><img src=' {PhotoAddress}' alt='' class='popupContentImage'/></div>" +
                        "<a href='" + window.location.origin + "/objects/zone/zoneObject?id={ZoneID}'><div style='background-color:#F6BD60;font-family:FiraGO_book;font-size:15px;line-height: 21px;padding:10px;cursor:pointer'>" +
                        "<div style='color: #000000;'>{Name}</div>" +
                        "</div></a>" +
                        "</div>";
                    return result;
                }



                visualZoneLayer = new MapImageLayer({
                    url: "http://memkvidreoba.gov.ge:6080/arcgis/rest/services/Culture/CulturePortalService/MapServer",
                    title: "ძეგლის ვიზუალური დაცვის არეალი",
                    visible: false,
                    opacity: .5,
                    listMode: "hide-children",
                    sublayers: [
                        {
                            title: " ",
                            id: 6,

                            definitionExpression:  ""
                        }
                    ],
                    minScale: 150000,
                    maxScale: 4000,
                });
                map.add(visualZoneLayer);

                physZoneLayer = new MapImageLayer({
                    url: "http://memkvidreoba.gov.ge:6080/arcgis/rest/services/Culture/CulturePortalService/MapServer",
                    title: "ძეგლის ფიზიკური დაცვის არეალი",
                    listMode: "hide-children",
                    visible: false,
                    opacity: .5,
                    sublayers: [
                        {
                            title: " ",
                            id: 5,

                            definitionExpression: "",

                        }
                    ],
                    minScale: 20000,
                });
                map.add(physZoneLayer);

                immovableLayer = new MapImageLayer({
                    url: "http://memkvidreoba.gov.ge:6080/arcgis/rest/services/Culture/CulturePortalService/MapServer",
                    listMode: "hide-children",
                    title: "ძეგლის/ობიექტის საზღვარი",
                    visible: false,
                    opacity: .7,
                    sublayers: [
                        {
                            id: 4,
                            title: " ",

                            definitionExpression:  "",
                        },

                    ],
                    minScale: 10000,
                });
                map.add(immovableLayer);

                const singlerenderer = {
                    type: "unique-value",
                    field: "Variety",
                    field2: "Status",
                    field3: "Category",
                    fieldDelimiter: ", ",
                    defaultSymbol: {
                        type: "picture-marker",
                        url: "/mapicons/red.svg",
                        width: "24px",
                        height: "24px"
                    },
                    uniqueValueInfos: [
                        {
                            value: "1, 1, 1",
                            symbol: this.getSymbol(1, Color.Obieqti),
                            label: "არქეოლოგიური"
                        },
                        {
                            value: "2, 1, 1",
                            symbol: this.getSymbol(2, Color.Obieqti),
                            label: "არქიტექტურის"
                        },
                        {
                            value: "3, 1, 1",
                            symbol: this.getSymbol(3, Color.Obieqti),
                            label: "საინჟინრო"
                        },
                        {
                            value: "4, 1, 1",
                            symbol: this.getSymbol(4, Color.Obieqti),
                            label: "ქალაქთმშენებლობის (ურბანული)"
                        },
                        {
                            value: "5, 1, 1",
                            symbol: this.getSymbol(5, Color.Obieqti),
                            label: "საბაღე-საპარკო ხელოვნებისა და ლანდშაფტური არქიტექტურის"
                        },
                        {
                            value: "6, 1, 1",
                            symbol: this.getSymbol(6, Color.Obieqti),
                            label: "პალეოგრაფიული"
                        },
                        {
                            value: "7, 1, 1",
                            symbol: this.getSymbol(7, Color.Obieqti),
                            label: "მონუმენტური სახვითი ხელოვნების"
                        },
                        {
                            value: "8, 1, 1",
                            symbol: this.getSymbol(8, Color.Obieqti),
                            label: "მემორიალური"
                        },
                        {
                            value: "9, 1, 1",
                            symbol: this.getSymbol(9, Color.Obieqti),
                            label: "ეთნოგრაფიული"
                        },
                        {
                            value: "10, 1, 1",
                            symbol: this.getSymbol(10, Color.Obieqti),
                            label: "სახვითი ხელოვნების"
                        },
                        {
                            value: "11, 1, 1",
                            symbol: this.getSymbol(11, Color.Obieqti),
                            label: "მეცნიერების, ტექნიკის ან მრეწველობის განვითარებასთან დაკავშირებული"
                        },
                        {
                            value: "1, 2, 1",
                            symbol: this.getSymbol(1, Color.Obieqti),
                            label: "არქეოლოგიური"
                        },
                        {
                            value: "2, 2, 1",
                            symbol: this.getSymbol(2, Color.Obieqti),
                            label: "არქიტექტურის"
                        },
                        {
                            value: "3, 2, 1",
                            symbol: this.getSymbol(3, Color.Obieqti),
                            label: "საინჟინრო"
                        },
                        {
                            value: "4, 2, 1",
                            symbol: this.getSymbol(4, Color.Obieqti),
                            label: "ქალაქთმშენებლობის (ურბანული)"
                        },
                        {
                            value: "5, 2, 1",
                            symbol: this.getSymbol(5, Color.Obieqti),
                            label: "საბაღე-საპარკო ხელოვნებისა და ლანდშაფტური არქიტექტურის"
                        },
                        {
                            value: "6, 2, 1",
                            symbol: this.getSymbol(6, Color.Obieqti),
                            label: "პალეოგრაფიული"
                        },
                        {
                            value: "7, 2, 1",
                            symbol: this.getSymbol(7, Color.Obieqti),
                            label: "მონუმენტური სახვითი ხელოვნების"
                        },
                        {
                            value: "8, 2, 1",
                            symbol: this.getSymbol(8, Color.Obieqti),
                            label: "მემორიალური"
                        },
                        {
                            value: "9, 2, 1",
                            symbol: this.getSymbol(9, Color.Obieqti),
                            label: "ეთნოგრაფიული"
                        },
                        {
                            value: "10, 2, 1",
                            symbol: this.getSymbol(10, Color.Obieqti),
                            label: "სახვითი ხელოვნების"
                        },
                        {
                            value: "11, 2, 1",
                            symbol: this.getSymbol(11, Color.Obieqti),
                            label: "მეცნიერების, ტექნიკის ან მრეწველობის განვითარებასთან დაკავშირებული"
                        },
                        {
                            value: "1, 3, 1",
                            symbol: this.getSymbol(1, Color.Zegli),
                            label: "არქეოლოგიური"
                        },
                        {
                            value: "2, 3, 1",
                            symbol: this.getSymbol(2, Color.Zegli),
                            label: "არქიტექტურის"
                        },
                        {
                            value: "3, 3, 1",
                            symbol: this.getSymbol(3, Color.Zegli),
                            label: "საინჟინრო"
                        },
                        {
                            value: "4, 3, 1",
                            symbol: this.getSymbol(4, Color.Zegli),
                            label: "ქალაქთმშენებლობის (ურბანული)"
                        },
                        {
                            value: "5, 3, 1",
                            symbol: this.getSymbol(5, Color.Zegli),
                            label: "საბაღე-საპარკო ხელოვნებისა და ლანდშაფტური არქიტექტურის"
                        },
                        {
                            value: "6, 3, 1",
                            symbol: this.getSymbol(6, Color.Zegli),
                            label: "პალეოგრაფიული"
                        },
                        {
                            value: "7, 3, 1",
                            symbol: this.getSymbol(7, Color.Zegli),
                            label: "მონუმენტური სახვითი ხელოვნების"
                        },
                        {
                            value: "8, 3, 1",
                            symbol: this.getSymbol(8, Color.Zegli),
                            label: "მემორიალური"
                        },
                        {
                            value: "9, 3, 1",
                            symbol: this.getSymbol(9, Color.Zegli),
                            label: "ეთნოგრაფიული"
                        },
                        {
                            value: "10, 3, 1",
                            symbol: this.getSymbol(10, Color.Zegli),
                            label: "სახვითი ხელოვნების"
                        },
                        {
                            value: "11, 3, 1",
                            symbol: this.getSymbol(11, Color.Zegli),
                            label: "მეცნიერების, ტექნიკის ან მრეწველობის განვითარებასთან დაკავშირებული"
                        },
                        {
                            value: "1, 3, 5",
                            symbol: this.getSymbol(1, Color.ErovnuliZegli),
                            label: "არქეოლოგიური"
                        },
                        {
                            value: "2, 3, 5",
                            symbol: this.getSymbol(2, Color.ErovnuliZegli),
                            label: "არქიტექტურის"
                        },
                        {
                            value: "3, 3, 5",
                            symbol: this.getSymbol(3, Color.ErovnuliZegli),
                            label: "საინჟინრო"
                        },
                        {
                            value: "4, 3, 5",
                            symbol: this.getSymbol(4, Color.ErovnuliZegli),
                            label: "ქალაქთმშენებლობის (ურბანული)"
                        },
                        {
                            value: "5, 3, 5",
                            symbol: this.getSymbol(5, Color.ErovnuliZegli),
                            label: "საბაღე-საპარკო ხელოვნებისა და ლანდშაფტური არქიტექტურის"
                        },
                        {
                            value: "6, 3, 5",
                            symbol: this.getSymbol(6, Color.ErovnuliZegli),
                            label: "პალეოგრაფიული"
                        },
                        {
                            value: "7, 3, 5",
                            symbol: this.getSymbol(7, Color.ErovnuliZegli),
                            label: "მონუმენტური სახვითი ხელოვნების"
                        },
                        {
                            value: "8, 3, 5",
                            symbol: this.getSymbol(8, Color.ErovnuliZegli),
                            label: "მემორიალური"
                        },
                        {
                            value: "9, 3, 5",
                            symbol: this.getSymbol(9, Color.ErovnuliZegli),
                            label: "ეთნოგრაფიული"
                        },
                        {
                            value: "10, 3, 5",
                            symbol: this.getSymbol(10, Color.ErovnuliZegli),
                            label: "სახვითი ხელოვნების"
                        },
                        {
                            value: "11, 3, 5",
                            symbol: this.getSymbol(11, Color.ErovnuliZegli),
                            label: "მეცნიერების, ტექნიკის ან მრეწველობის განვითარებასთან დაკავშირებული"
                        }, {
                            value: "1, 3, 6",
                            symbol: this.getSymbol(1, Color.MsoflioZegli),
                            label: "არქეოლოგიური"
                        },
                        {
                            value: "2, 3, 6",
                            symbol: this.getSymbol(2, Color.MsoflioZegli),
                            label: "არქიტექტურის"
                        },
                        {
                            value: "3, 3, 6",
                            symbol: this.getSymbol(3, Color.MsoflioZegli),
                            label: "საინჟინრო"
                        },
                        {
                            value: "4, 3, 6",
                            symbol: this.getSymbol(4, Color.MsoflioZegli),
                            label: "ქალაქთმშენებლობის (ურბანული)"
                        },
                        {
                            value: "5, 3, 6",
                            symbol: this.getSymbol(5, Color.MsoflioZegli),
                            label: "საბაღე-საპარკო ხელოვნებისა და ლანდშაფტური არქიტექტურის"
                        },
                        {
                            value: "6, 3, 6",
                            symbol: this.getSymbol(6, Color.MsoflioZegli),
                            label: "პალეოგრაფიული"
                        },
                        {
                            value: "7, 3, 6",
                            symbol: this.getSymbol(7, Color.MsoflioZegli),
                            label: "მონუმენტური სახვითი ხელოვნების"
                        },
                        {
                            value: "8, 3, 6",
                            symbol: this.getSymbol(8, Color.MsoflioZegli),
                            label: "მემორიალური"
                        },
                        {
                            value: "9, 3, 6",
                            symbol: this.getSymbol(9, Color.MsoflioZegli),
                            label: "ეთნოგრაფიული"
                        },
                        {
                            value: "10, 3, 6",
                            symbol: this.getSymbol(10, Color.MsoflioZegli),
                            label: "სახვითი ხელოვნების"
                        },
                        {
                            value: "11, 3, 6",
                            symbol: this.getSymbol(11, Color.MsoflioZegli),
                            label: "მეცნიერების, ტექნიკის ან მრეწველობის განვითარებასთან დაკავშირებული"
                        }

                    ]
                };

                const defaultRenderer = {
                    type: "unique-value",
                    field: "Status",
                    field2: "Category",
                    fieldDelimiter: ", ",
                    defaultSymbol: {
                        type: "simple-marker",
                        size: 6,
                        color: "rgba(147, 25, 40, 1)",
                        outline: {
                            color: "rgba(0, 0, 0, 1)",
                            width: 1
                        }
                    },
                    uniqueValueInfos: [
                        {
                            value: "1, 1",
                            symbol: {
                                type: "simple-marker",
                                size: 6,
                                color: "rgba(147, 25, 40, 1)",
                                outline: {
                                    color: "rgba(0, 0, 0, 1)",
                                    width: 1
                                }
                            },
                            label: "არქეოლოგიური"
                        },
                        {
                            value: "2, 1",
                            symbol: {
                                type: "simple-marker",
                                size: 6,
                                color: "rgba(147, 25, 40, 1)",
                                outline: {
                                    color: "rgba(0, 0, 0, 1)",
                                    width: 1
                                }
                            },
                            label: "არქეოლოგიური"
                        },
                        {
                            value: "3, 1",
                            symbol: {
                                type: "simple-marker",
                                size: 6,
                                color: "rgba(221, 73, 171, 1)",
                                outline: {
                                    color: "rgba(0, 0, 0, 1)",
                                    width: 1
                                }
                            },
                            label: "არქეოლოგიური"
                        },
                        {
                            value: "3, 5",
                            symbol: {
                                type: "simple-marker",
                                size: 6,
                                color: "rgba(255, 0, 169, 1)",
                                outline: {
                                    color: "rgba(0, 0, 0, 1)",
                                    width: 1
                                }
                            },
                            label: "არქეოლოგიური"
                        },
                        {
                            value: "3, 6",
                            symbol: {
                                type: "simple-marker",
                                size: 6,
                                color: "rgba(245, 166, 35, 1)",
                                outline: {
                                    color: "rgba(0, 0, 0, 1)",
                                    width: 1
                                }
                            },
                            label: "არქეოლოგიური"
                        }
                    ]
                };

                const clusterConfig = {
                    type: "cluster",
                    clusterRadius: "50px",
                    clusterMinSize: "22px",
                    clusterMaxSize: "60px",
                    labelingInfo: [
                        {
                            deconflictionStrategy: "none",
                            labelExpressionInfo: {
                                expression: "Text($feature.cluster_count, '#,###')"
                            },
                            symbol: {
                                type: "text",
                                color: "#ffffff",
                                font: {
                                    weight: "bold",
                                    family: "Noto Sans",
                                    size: "12px"
                                }
                            },
                            labelPlacement: "center-center"
                        }
                    ]
                };


                const renderer = {
                    type: "unique-value",
                    field: "cluster_count",
                    valueExpression: "IIF($feature.cluster_count > 1, 'group',  $feature.forCluster)",
                    defaultSymbol: {
                        type: "picture-marker",
                        url: "/mapicons/red.svg",
                        width: "24px",
                        height: "24px"
                    },
                    uniqueValueInfos: [
                        {
                            value: "1, 1",
                            symbol: {
                                type: "simple-marker",
                                size: 6,
                                color: "rgba(147, 25, 40, 1)",
                                outline: {
                                    color: "rgba(0, 0, 0, 1)",
                                    width: 1
                                }
                            },
                            label: "არქეოლოგიური"
                        },
                        {
                            value: "2, 1",
                            symbol: {
                                type: "simple-marker",
                                size: 6,
                                color: "rgba(147, 25, 40, 1)",
                                outline: {
                                    color: "rgba(0, 0, 0, 1)",
                                    width: 1
                                }
                            },
                            label: "არქეოლოგიური"
                        },
                        {
                            value: "3, 1",
                            symbol: {
                                type: "simple-marker",
                                size: 6,
                                color: "rgba(221, 73, 171, 1)",
                                outline: {
                                    color: "rgba(0, 0, 0, 1)",
                                    width: 1
                                }
                            },
                            label: "არქეოლოგიური"
                        },
                        {
                            value: "3, 5",
                            symbol: {
                                type: "simple-marker",
                                size: 6,
                                color: "rgba(255, 0, 169, 1)",
                                outline: {
                                    color: "rgba(0, 0, 0, 1)",
                                    width: 1
                                }
                            },
                            label: "არქეოლოგიური"
                        },
                        {
                            value: "3, 6",
                            symbol: {
                                type: "simple-marker",
                                size: 6,
                                color: "rgba(245, 166, 35, 1)",
                                outline: {
                                    color: "rgba(0, 0, 0, 1)",
                                    width: 1
                                }
                            },
                            label: "არქეოლოგიური"
                        },
                        {
                            value: 'group',
                            symbol: {
                                type: "simple-marker",
                                size: 4,
                                color: "rgba(229, 121, 89, 0.7)",
                                outline: {
                                    color: "rgba(229, 121, 89, 0.3)",
                                    width: 4
                                }
                            },
                            label: "Interstate"
                        },
                    ]
                };


                Flayer = new FeatureLayer({
                    url: "http://memkvidreoba.gov.ge:6080/arcgis/rest/services/Culture/TestPoints/MapServer/0",
                    title: "ძეგლი/ობიექტი",
                    outFields: ["*"],
                    visible: false,
                    renderer: renderer,
                    // renderer: defaultRenderer,
                    popupTemplate: {
                        title: '',
                        content: immovablePopup(),

                    } ,
                    definitionExpression:  "",
                    featureReduction: clusterConfig
                    //  screenSizePerspectiveEnabled: true
                });

                map.add(Flayer);

                
                // ----------------------museum --------------------------


                var museumLayer = new MapImageLayer({
                    url: "http://memkvidreoba.gov.ge:6080/arcgis/rest/services/Culture/CulturePortalService/MapServer",
                    listMode: "hide-children",
                    title: "მუზეუმ-ნაკრძალი",
                    visible: false,
                    opacity: .8,
                    sublayers: [
                        {
                            title: " ",
                            id: 8,

                            popupTemplate:  {
                                title: "",
                                outFields: ["MuseumID", "PhotoAddress", "Name"],
                                content: museumPopup
                            } ,
                            definitionExpression: ""
                        },

                    ]
                });
                map.add(museumLayer);

                var museumPointLayer = new MapImageLayer({
                    url: "http://memkvidreoba.gov.ge:6080/arcgis/rest/services/Culture/CulturePortalService/MapServer",
                    listMode: "hide-children",
                    title: "მუზეუმი",
                    visible: false,
                    sublayers: [
                        {
                            title: " ",
                            id: 7,
                            popupTemplate:  {
                                title: "",
                                outFields: ["MuseumID", "PhotoAddress", "Name"],
                                content: museumPopup
                            } ,
                            definitionExpression:  ""
                        },

                    ]
                });
                map.add(museumPointLayer);




                watchUtils.watch(measurement.viewModel, "state", (value) => {

                    if (value === "measuring") {
                        if (measurement.activeWidget.messages != null) {
                            measurement.activeWidget.messages.distance = "მანძილი:"
                            measurement.activeWidget.messages.area = "ფართობი:"
                            measurement.activeWidget.messages.perimeter = "პერიმეტრი:"

                        }

                        if (measurement.viewModel.activeViewModel.tool.messages != null) {
                            measurement.viewModel.activeViewModel.tool.messages.units.kilometers.abbr = 'კმ';
                            measurement.viewModel.activeViewModel.tool.messages.units.meters.abbr = 'მ';
                            measurement.viewModel.activeViewModel.tool.messages.units['square-kilometers'].abbr = 'კმ²';
                            measurement.viewModel.activeViewModel.tool.messages.units['square-meters'].abbr = 'მ²';
                        }
                    }

                    var symbol = {
                        type: "simple-line",
                        color: "#E57959",
                        width: "4px",
                        style: "solid"
                    };

                    if (view.map.layers != null && view.map.layers.items.length > 5) {
                        //if (view.activeTool != null && view.activeTool.messages != null) {
                        //    view.activeTool.messages.units.kilometers.abbr = 'კმ';
                        //    view.activeTool.messages.units['square-meters'].abbr = 'მ²';
                        //    view.activeTool.messages.units['meters'].abbr = 'მ';
                        //}

                        var layer = view.map.layers.items[5];
                        if (layer.layers != null && layer.layers.items[0].graphics.items.length === 3) {
                            layer.layers.items[0].graphics.items[0].symbol = symbol;
                            layer.layers.items[0].graphics.items[1].visible = false;
                            layer.layers.items[0].graphics.items[2].visible = false;
                        }

                    }
                });


              
                view.watch("scale", function (newValue) {
                    if (newValue <= 10000) {
                        Flayer.renderer = singlerenderer;
                        Flayer.featureReduction = null;
                    }
                    else {
                        if (newValue <= 1500000) {
                            Flayer.renderer = defaultRenderer;
                            Flayer.featureReduction = null;
                        }
                        else {
                            Flayer.renderer = renderer;
                            Flayer.featureReduction = clusterConfig;
                        }

                    }
                });

                var zonePointsLayer = new GraphicsLayer({
                    "listMode": "hide"
                });
                map.add(zonePointsLayer);


            });
    }

    getSymbol(variety, type) {
        var symbol;
        symbol = {
            type: "picture-marker",
            url: "/mapicons/" + variety + type + ".svg",
            width: "26px",
            height: "26px"
        };
        return symbol;
    }


    getSelectionSymbol() {
        var symbol;
        symbol = {

            type: "simple-marker",  // autocasts as new SimpleMarkerSymbol()
            style: "circle",
            color: "blue",
            size: "26px",  // pixels
            outline: {  // autocasts as new SimpleLineSymbol()
                color: [255, 255, 0],
                width: 3  // points
            }
        };
        return symbol;
    }

    getGraphicPopup(photo, name, variety, id) {
        return (
            "<div style='width:250px;max-height:280px'>" +
            "<div style='width:250px;height:180px;background-color:#F6BD60;'><img variant='top' src='" + photo + "' alt='' /></div>" +
            "<a href='" + window.location.origin + "/objects/immovable/immovableObject?id=" + id + "'><div style='background-color:#F6BD60;font-family:FiraGO_book;font-size:15px;line-height: 21px;padding:10px'>" +
            "<div style='color: #000000;'>" + name + "</div>" +
            "<div style='color: #636363;font-size:12px;'>" + variety + "</div></div></a>" +
            "</div>"
        );


    }

    componentWillUnmount() {
        if (this.view) {
            // destroy the map view
            this.view.container = null;
        }

    }

   




    render() {
      
        return (
            <div className={this.props.className} id="forElements" style={{ height:'100%' }}>
              
                <div style={{ paddingBottom: '1px' }} className="contactMap webmap" ref={this.mapRef} >
                    {this.props.children}
                    {
                        this.props.view !== true ?
                            <div className="userMessage" style={{}}>რუკაზე ადგილის მოსანიშნად დასვით პინი</div>
                            : <></>
                    }
                </div>

                <div id="toolbarDiv" className={`toolbarDiv esri-component esri-widget displayNone ${this.props.mapClass != null && this.props.mapClass !== '' ? 'objectMapTollBar' : ''}`} >
                    <button
                        id="distance"
                        className="toolbarIcon esri-widget--button esri-interactive  esri-icon-measure-line"
                        data-tooltip="მანძილის მზომი"
                    ></button>
                    <button
                        id="area"
                        className="toolbarIcon esri-widget--button esri-interactive esri-icon-measure-area"
                        data-tooltip="ფართობის მზომი"
                    ></button>
                    <button
                        id="clear"
                        className="toolbarIcon esri-widget--button esri-interactive esri-icon-erase"
                        data-tooltip="მონაცემების გასუფთავება"
                    ></button>
                    <button
                        id="close"
                        className="toolbarIcon esri-widget--button esri-interactive esri-icon-close"
                        data-tooltip="დახურვა"
                    ></button>
                </div>

                <div id="menuDiv" className={`displayNone ${this.props.mapClass != null && this.props.mapClass !== '' ? 'objectMapTollBar' : ''}`}>
                    <div className="esri-widget--button esricutomButton esri-icon-measure" onClick={this.showMeasurement.bind(this)} data-tooltip="მანძილის და ფართობის მზომი"></div>
                    <WebMapLegend
                        archZoneCheck={false}
                        landZoneCheck={false}
                        regZoneCheck={false}
                        istganZoneCheck={false}
                        visZoneCheck={false}
                        physZoneCheck={false}                   
                        immCheck={false}
                        immPointCheck={false}
                        museumCheck={false}
                        musResCheck={false}
                        legendClass={this.props.mapClass != null && this.props.mapClass !== '' ? 'objectMapLegend' : ''}
                    ></WebMapLegend>
                </div>

            </div >
        );
    }
}